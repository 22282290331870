import { useTranslation } from 'next-i18next'
import { ChangeEvent, useCallback } from 'react'
import clsx from 'clsx'
import { ErrorMessage, FieldProps } from 'formik'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import Spinner from 'components/Spinner'

import { getZodMessage } from 'utils/validation.util'

import { IInputFieldProps } from '../types'

const InputField = <
  FromValues extends Record<string, unknown> = Record<string, unknown>
>({
  field,
  form,
  label,
  helperText,
  inputClassName,
  isLoading,
  onChange,
  className,
  required,
  ...props
}: IInputFieldProps & FieldProps<string, FromValues>) => {
  const { t } = useTranslation(['zod'])
  z.setErrorMap(makeZodI18nMap(t))

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value.trim()
      form.setFieldValue(field.name, value)
      field.onChange(e)

      onChange?.(value)
    },
    [field, form, onChange]
  )

  return (
    <div className={clsx('relative', className)}>
      {label && (
        <label
          className="block pt-4 pb-2 text-lg font-semibold tracking-wide text-gray-900"
          htmlFor={field.name}
        >
          {label}
          {required && <span className="ml-1 font-bold text-red-550">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          {...field}
          {...props}
          required
          onChange={changeHandler}
          className={clsx(
            'block h-10 w-full rounded-sm border-0 border-b-2 border-gray-200 text-gray-700 transition-colors focus:border-gray-400 focus:border-black',
            inputClassName
          )}
        />
        <Spinner
          isVisible={isLoading}
          size={36}
          className="pointer-events-none absolute top-[50%] right-0 -translate-y-1/2"
        />
      </div>
      <div className="absolute pt-1 text-xs tracking-wider antialiased">
        {helperText && (
          <div className="fade-in text-xs tracking-wider text-gray-400 antialiased">
            {helperText}
          </div>
        )}
        <ErrorMessage
          name={field.name}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="fade-in text-medium text-error-400">
                {getZodMessage(msg, t(msg, { ns: 'zod' }))}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default InputField
