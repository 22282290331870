import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'
import { SizeVariant } from 'enums'

import { Button } from 'components/buttons'

import useModalStore from 'store/useModalStore'
import { useResendConfirmEmail } from 'hooks/useAuth'
import useTimer from 'hooks/useTimer'

import InformationModal from './InformationModal'
import { IModal } from './types'

const ConfirmEmailModal = ({
  isOpen,
  showResend,
  onClose
}: IModal & { showResend?: boolean }) => {
  const { t } = useTranslation(['auth'])
  const { emailResend } = useModalStore()
  const { onStart, minutes, seconds, enable } = useTimer({
    cookieName: 'sent-email-confirmation'
  })
  const { mutate: onResend, isLoading } = useResendConfirmEmail()

  return (
    <InformationModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('signup.title', { ns: 'auth' })}
      subtitle={t('emailVerification.title', { ns: 'auth' })}
    >
      <p>{t('emailVerification.emailSent', { ns: 'auth' })}</p>
      <p>{t('emailVerification.emailSent2', { ns: 'auth' })}</p>
      {showResend && emailResend && (
        <div className="mt-5 flex flex-col items-center justify-center">
          <Button
            isLoading={isLoading}
            disabled={isLoading || !enable}
            size={SizeVariant.Small}
            onClick={() => {
              onResend(emailResend, {
                onSuccess: () => {
                  onStart()
                  toast.success(
                    t('toast.resendConfirmationMailSend', { ns: 'auth' })
                  )
                }
              })
            }}
          >
            {t('resend.title', { ns: 'auth' })}
          </Button>
          {!enable && (
            <p className="mt-3 text-xs text-gray-400">
              {t('resend.delay', {
                ns: 'auth',
                delay: `${minutes}:${seconds}`
              })}
            </p>
          )}
        </div>
      )}
    </InformationModal>
  )
}

export default ConfirmEmailModal
