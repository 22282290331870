import { useTranslation } from 'next-i18next'

const CopyJobAd = () => {
  const { t } = useTranslation(['crm'])
  return (
    <div className="my-2 text-center text-xl font-semibold text-black sm:text-3xl">
      {t('copyJobAdModal', { ns: 'crm' })}
    </div>
  )
}
export default CopyJobAd
