import { useCallback, useMemo, useState } from 'react'

import { IPaymentType } from 'components/Fields/PaymentTypeRadio'
import { ISubscriptionTimePlans } from 'components/PricingPlanSection/types'

import useRestrictionsStore from 'store/useRestrictionsStore'
import useSubscriptionPlansStore from 'store/useSubscriptionPlansStore'
import { useCreateOrganisationSubscription } from 'hooks/useSubscriptions'

import { handleQueryError } from 'utils/queryErrorHandler'
import { times } from 'constants/subscription'

import { ISubscriptionsPrice, IUseChooseSubscriptionModalProps } from './types'

export enum SubscriptionModalSteps {
  SelectPlan = 'selectPlan',
  SelectMethod = 'selectMethod'
}

const useChooseSubscriptionModal = ({
  onClose,
  nextSubscriptionPlan,
  nextSubscriptionPeriod,
  afterSubmit,
  isChangeSubscription
}: IUseChooseSubscriptionModalProps) => {
  const prices = useSubscriptionPlansStore((state) => state.mappedPricing)
  const planSlice = useSubscriptionPlansStore((state) => state.planSlice)
  const [step, isSetStep] = useState(SubscriptionModalSteps.SelectPlan)
  const [paymentType, setPaymentType] = useState<IPaymentType>('CreditCard')
  const [selectedPlanType, setSelectedPlan] = useState(nextSubscriptionPlan)
  const [showConfirmation, setShowConfirmation] = useState<
    'toPro' | 'toBasic' | null
  >(null)

  const [selectedTime, setSelectedTime] = useState<ISubscriptionTimePlans>(
    nextSubscriptionPeriod === 'fortnight'
      ? times[1]
      : nextSubscriptionPeriod ?? times[1]
  )

  const countActiveJobAds = useRestrictionsStore(
    (state) => state.organisation?.countActiveJobAds
  )

  const isRestricted = useMemo(
    () => !!countActiveJobAds && countActiveJobAds > 3,

    [countActiveJobAds]
  )

  const { mutateAsync, isLoading: isSaving } =
    useCreateOrganisationSubscription({
      onError: handleQueryError
    })

  const subscriptionPrice = useMemo<ISubscriptionsPrice>(
    () => ({
      time: selectedTime,
      trial: prices?.trial?.[selectedTime],
      basic: prices?.basic?.[selectedTime],
      pro: prices?.pro?.[selectedTime]
    }),
    [prices?.basic, prices?.pro, prices?.trial, selectedTime]
  )

  const createSubscription = useCallback(
    async (isConfirmed: boolean) => {
      if (selectedPlanType !== 'basic' && selectedPlanType !== 'pro') {
        onClose?.()
        return
      }

      const planId = subscriptionPrice[selectedPlanType]?.planId

      if (!planId) {
        onClose?.()
        return
      }

      if (selectedPlanType == 'pro' && !isConfirmed) {
        setShowConfirmation('toPro')
        return
      }
      if (selectedPlanType == 'basic' && !isConfirmed) {
        setShowConfirmation('toBasic')
        return
      }

      const subscription = await mutateAsync({ planId })

      return subscription
    },
    [mutateAsync, onClose, selectedPlanType, subscriptionPrice]
  )

  const handleSubmit = useCallback(async () => {
    if (step === SubscriptionModalSteps.SelectPlan) {
      await createSubscription(false)
    } else {
      const subscription = await createSubscription(true)
      if (subscription) {
        afterSubmit?.(subscription, paymentType)
      }
    }
  }, [afterSubmit, createSubscription, paymentType, step])

  const handleCancel = useCallback(() => {
    if (step === SubscriptionModalSteps.SelectPlan) {
      onClose?.()
    } else {
      isSetStep(SubscriptionModalSteps.SelectPlan)
    }
  }, [onClose, step])

  const textSubmit = useMemo(
    () => (step == SubscriptionModalSteps.SelectPlan ? 'continue' : 'payNow'),
    [step]
  )

  const textCancel = useMemo(
    () => (step == SubscriptionModalSteps.SelectPlan ? 'cancel' : 'back'),
    [step]
  )

  const secondaryModalsSubmit = useCallback(async () => {
    if (isChangeSubscription && showConfirmation == 'toBasic') {
      const subscription = await createSubscription(true)
      if (subscription) {
        afterSubmit?.(subscription, paymentType)
      }
    } else {
      isSetStep(SubscriptionModalSteps.SelectMethod)
    }
  }, [
    afterSubmit,
    createSubscription,
    isChangeSubscription,
    paymentType,
    showConfirmation
  ])

  return {
    planSlice,
    selectedPlanType,
    subscriptionPrice,
    isSaving,
    isRestricted,
    selectedTime,
    showConfirmation,
    selectPlan: setSelectedPlan,
    setSelectedTime,
    handleSubmit,
    handleCancel,
    step,
    setShowConfirmation,
    paymentType,
    setPaymentType,
    textSubmit,
    textCancel,
    secondaryModalsSubmit
  }
}
export default useChooseSubscriptionModal
