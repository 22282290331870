import { useCallback, useEffect, useMemo, useState } from 'react'
import { Field } from 'formik'
import { debounce } from 'throttle-debounce'

import InputField from './components/InputField'
import { IDebounceTextInput } from './types'

const DebounceTextInput = ({
  debounceTime = 300,
  onDebounceChange,
  ...props
}: IDebounceTextInput) => {
  const [inputValue, setInputValue] = useState('')

  const debounceHandler = useMemo<debounce<() => void>>(
    () =>
      debounce(debounceTime, async () => {
        await onDebounceChange?.(inputValue)
      }),
    [debounceTime, inputValue, onDebounceChange]
  )

  const onChange = useCallback((val: string) => {
    setInputValue(val)
  }, [])

  useEffect(() => {
    debounceHandler()
    return () => {
      debounceHandler.cancel()
    }
  }, [debounceHandler])

  return <Field component={InputField} {...props} onChange={onChange} />
}

export default DebounceTextInput
