import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

import useVerifyChangeEmail from 'hooks/useProfile/useVerifyChangeEmail'

import IVerifyChangeEmail from 'validators/changeEmailValidator'
import { handleQueryError } from 'utils/queryErrorHandler'

import { IModal } from '../types'

const useChangeEmailModal = ({ onClose }: Pick<IModal, 'onClose'>) => {
  const { t } = useTranslation(['userProfile'])
  const { mutate, isLoading } = useVerifyChangeEmail({
    onSuccess: () => {
      toast.success(t('toast.confirmEmailChanging', { ns: 'userProfile' }))
      onClose?.()
    },
    onError: handleQueryError
  })

  const onSubmit = useCallback(
    (values: IVerifyChangeEmail) => {
      mutate(values)
    },
    [mutate]
  )

  return { isLoading, onSubmit, t }
}

export default useChangeEmailModal
