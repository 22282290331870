import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ICreateOrganisationSubscriptionRequest } from 'data-transfers/requests'
import { IChangeSubscriptionPlanResponse } from 'data-transfers/responses'

import { IUseMutation } from 'hooks/types'

import { subscriptionsKeys } from './keys'
import { createOrganisationSubscription } from './subscription.service'

const useCreateOrganisationSubscription = ({
  onSuccess,
  ...options
}: IUseMutation<
  IChangeSubscriptionPlanResponse,
  unknown,
  ICreateOrganisationSubscriptionRequest
> = {}) => {
  const queryClient = useQueryClient()
  return useMutation(createOrganisationSubscription, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        subscriptionsKeys.organisationSubscriptions()
      )
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useCreateOrganisationSubscription
