/* eslint-disable no-fallthrough */
import { z } from 'zod'

import { CreationStep } from '../enums'
import {
  advertCompanyProposalValidator,
  advertDescriptionValidator,
  advertEducationValidator,
  advertIndustriesValidator,
  advertInformationValidator,
  advertLanguageValidator,
  advertResponsibilitiesValidator,
  advertSkillsValidator
} from '../validators/adStepValidator'
import { advertValidator } from '../validators/advertValidator'

export const draftValidator = (step: CreationStep) => {
  let newAdValidator = z.object({})

  switch (step) {
    case CreationStep.Preview:
      newAdValidator = newAdValidator.merge(advertCompanyProposalValidator)
    case CreationStep.Proposal:
      newAdValidator = newAdValidator.merge(advertDescriptionValidator)
    case CreationStep.Description:
      newAdValidator = newAdValidator.merge(advertResponsibilitiesValidator)
    case CreationStep.Responsibilities:
      newAdValidator = newAdValidator.merge(advertLanguageValidator)
    case CreationStep.Languages:
      newAdValidator = newAdValidator.merge(advertEducationValidator)
    case CreationStep.Industries:
      newAdValidator = newAdValidator.merge(advertIndustriesValidator)
    case CreationStep.Education:
      newAdValidator = newAdValidator.merge(advertSkillsValidator)
    case CreationStep.Skills:
      newAdValidator = newAdValidator.merge(advertInformationValidator)
      break
    default:
      return advertValidator
  }

  return newAdValidator
}
