import PricingPlan from 'components/PricingPlan'
import SubscriptionTimesSelector from 'components/SubscriptionTimesSelector'

import { ISubscriptionPlanSelectionProps } from '../types'

const SubscriptionPlanSelection = ({
  selectedTime,
  setSelectedTime,
  planSlice,
  selectedPlanType,
  selectPlan,
  subscriptionPrice
}: ISubscriptionPlanSelectionProps) => {
  return (
    <>
      <SubscriptionTimesSelector
        className="mb-3 md:mb-5"
        selectedTime={selectedTime}
        onSelect={setSelectedTime}
        hideTrial
      />
      <div className="mb-5 grid max-w-5xl grid-cols-1 gap-10 text-left text-black md:grid-cols-2">
        {planSlice
          ?.filter((plan) => plan.primary.planType !== 'trial')
          .map((plan, index) => (
            <div className="flex" data-cy={`plan-${index}`} key={index}>
              <PricingPlan
                startNowButton={false}
                tiny
                active={plan.primary.planType === selectedPlanType}
                planInfo={plan}
                time={selectedTime}
                price={subscriptionPrice[plan.primary.planType]?.price ?? 0}
                showSelector
                className="w-full"
                onClick={() => {
                  selectPlan(plan.primary.planType)
                }}
              />
            </div>
          ))}
      </div>
    </>
  )
}
export default SubscriptionPlanSelection
