import { useTranslation } from 'next-i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import {
  IOrganisation,
  IOrganisationOverallInformation
} from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import { DebounceTextInput } from 'components/Fields/DebounceTextInput'
import Input from 'components/Fields/Input'
import SelectListItem from 'components/Fields/SelectListItem'
import { CountryAutocomplete } from 'components/selects'
import { IconArrow } from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import {
  checkIsOrganisationExistsByCompanyCode,
  useSaveOrganisationStep
} from 'hooks/useOrganisation'
import useOrgInfo from 'hooks/useOrgInfo'

import countries from 'data/countries'
import { orgStep0Validator } from 'validators/orgStepValidator'
import { getOrganisationDescriptions, removeEmptyProperties } from 'utils'
import { getZodMessage } from 'utils/validation.util'

import { IOrgStepProps } from './types'

type IFormContent = Pick<
  IOrganisation,
  | 'countryCode'
  | 'registrationNumber'
  | 'brandName'
  | 'legalName'
  | 'vatPayerNumber'
  | 'industryId'
  | 'websiteUrl'
  | 'descriptions'
> & {
  countryName?: string
}

const FormContent = ({ footer }: { footer?: ReactNode }) => {
  const { t } = useTranslation(['inputs', 'actions'])
  const tempOrg = useOrgNewStore((state) => state.tempOrg)
  const setTempOrg = useOrgNewStore((state) => state.setTempOrg)
  const [registrationNumber, setRegistrationNumber] = useState(
    tempOrg?.registrationNumber ?? ''
  )
  const { setFieldValue, setFieldError, setFieldTouched, values } =
    useFormikContext<IFormContent>()
  const [isLoading, setIsLoading] = useState(false)

  //Registration number length for different countries
  //Estonia: 8
  // Lithuania: 9
  // Latvia: 11
  const isAllowLoadOkredoData = useMemo(() => {
    const countryCode = values.countryCode?.toLowerCase()
    if (registrationNumber.length < 8 || !countryCode) {
      return false
    }
    if (countryCode == 'lt' && registrationNumber.length >= 9) {
      return true
    }
    if (countryCode == 'lv' && registrationNumber.length >= 11) {
      return true
    }
    if (countryCode == 'ee' && registrationNumber.length >= 8) {
      return true
    }
    return false
  }, [registrationNumber.length, values.countryCode])

  const { isFetching } = useOrgInfo({
    payload: {
      companyCode: registrationNumber,
      countryCode: values.countryCode ?? ''
    },
    options: {
      enabled: isAllowLoadOkredoData,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const prefiledData = removeEmptyProperties(data)
        setTempOrg(prefiledData)

        const { legalName, vatPayerNumber, website } = data

        if (legalName) {
          setFieldValue('legalName', legalName)
          setFieldValue('brandName', legalName)
        }
        if (vatPayerNumber) {
          setFieldValue('vatPayerNumber', vatPayerNumber)
        }
        if (website) {
          setFieldValue('websiteUrl', website)
        }
      }
    }
  })

  const onDebounceChange = useCallback(
    async (regNumber: string) => {
      const countryCode = values.countryCode?.toLowerCase()
      if (regNumber.length < 8 || !countryCode) {
        return
      }

      setIsLoading(true)
      const { isExist } = await checkIsOrganisationExistsByCompanyCode({
        registrationNumber: regNumber,
        countryCode
      })
      setIsLoading(false)

      if (isExist) {
        setFieldError(
          'registrationNumber',
          t('registrationNumber.alreadyExist', { ns: 'inputs' })
        )
        return
      }

      setRegistrationNumber(regNumber)
    },
    [setFieldError, t, values.countryCode]
  )

  return (
    <>
      <div
        className={clsx(
          'mb-5 grid grid-cols-1 gap-x-5 gap-y-4  md:grid-cols-2 lg:grid-cols-3'
        )}
      >
        <CountryAutocomplete
          name="countryCode"
          label={t('country.label', { ns: 'inputs' })}
          placeholder={t('country.placeholder', { ns: 'inputs' })}
          onSelect={(value) => {
            setFieldValue('countryCode', value?.code)
            setFieldValue('countryName', value?.name)
          }}
          onClean={() => {
            setFieldValue('countryCode', '')
            setFieldTouched('countryCode', true, true)
          }}
          selectedCountryName={values.countryName ?? null}
          isClearable
          required
        />
        <DebounceTextInput
          label={t('registrationNumber.label', { ns: 'inputs' })}
          name="registrationNumber"
          debounceTime={500}
          required
          isLoading={isFetching || isLoading}
          onDebounceChange={onDebounceChange}
        />
        <SelectListItem
          className="min-w-[17rem]"
          label={t('industry.label', { ns: 'inputs' })}
          placeholder={t('industry.placeholder', { ns: 'inputs' })}
          name="industryId"
          type="Industry"
          required
        />
        <Input
          label={t('brandName.label', { ns: 'inputs' })}
          placeholder={t('brandName.placeholder', { ns: 'inputs' })}
          name="brandName"
          required
        />
        <Input
          label={t('legalName.label', { ns: 'inputs' })}
          placeholder={t('legalName.placeholder', { ns: 'inputs' })}
          name="legalName"
          required
        />
        <Input
          label={t('vatPayerNumber.label', { ns: 'inputs' })}
          name="vatPayerNumber"
        />
        <Input
          label={t('website.label', { ns: 'inputs' })}
          placeholder={t('website.placeholder', { ns: 'inputs' })}
          name="websiteUrl"
        />
      </div>
      {footer || (
        <div className="flex items-center justify-end gap-x-4">
          <ErrorMessage
            name="competencies"
            render={(msg) =>
              typeof msg === 'string' ? (
                <div className="fade-in text-medium text-error-400">
                  {getZodMessage(msg, t(msg, { ns: 'zod' }))}
                </div>
              ) : null
            }
          />
          <Button className="animated-svg" type="submit" isLoading={isLoading}>
            <span className=" mr-2">{t('next', { ns: 'actions' })}</span>
            <IconArrow />
          </Button>
        </div>
      )}
    </>
  )
}

export const FormInformation = ({
  footer,
  onSubmit,
  initialValues
}: IOrgStepProps<IFormContent>) => {
  const { t } = useTranslation(['inputs'])
  const incStep = useOrgNewStore((state) => state.incStep)
  const tempOrg = useOrgNewStore((state) => state.tempOrg)
  const formValues: IFormContent = useMemo(() => {
    const content = initialValues ? initialValues : tempOrg

    return {
      countryCode: content?.countryCode ?? '',
      registrationNumber: content?.registrationNumber ?? '',
      brandName: content?.brandName ?? '',
      legalName: content?.legalName ?? '',
      vatPayerNumber: content?.vatPayerNumber ?? '',
      industryId: content?.industryId ?? '',
      websiteUrl: content?.websiteUrl ?? '',
      descriptions: content?.descriptions ?? [],

      countryName: content?.countryCode
        ? countries.find((x) => x.code === content?.countryCode)?.name
        : ''
    }
  }, [initialValues, tempOrg])

  const { mutate } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.OverallInformation,
    { onSuccess: () => incStep(OrganisationOnboardingStatus.Address) }
  )

  return (
    <Formik
      initialValues={formValues}
      validationSchema={toFormikValidationSchema(orgStep0Validator)}
      onSubmit={async (values, { setFieldError }) => {
        const { isExist } = await checkIsOrganisationExistsByCompanyCode({
          registrationNumber: values.registrationNumber,
          countryCode: values.countryCode?.toLocaleLowerCase() ?? ''
        })

        if (isExist) {
          setFieldError(
            'registrationNumber',
            t('registrationNumber.alreadyExist', { ns: 'inputs' })
          )
          return
        }
        const valuesWithOrgDescriptions = {
          ...values,
          descriptions: getOrganisationDescriptions(
            values.countryCode,
            values.descriptions
          )
        }
        if (onSubmit) {
          onSubmit(valuesWithOrgDescriptions)
          return
        }

        mutate({ data: values as IOrganisationOverallInformation })
      }}
    >
      <Form noValidate>
        <FormContent footer={footer} />
      </Form>
    </Formik>
  )
}

const OrgStep0 = ({ index, step }: { index: number; step?: string }) => {
  const { t } = useTranslation(['org_form'])
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)
  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
      description={t('overallInformation.description', { ns: 'org_form' })}
    >
      <FormInformation />
    </AccordionItem>
  )
}

export default OrgStep0
