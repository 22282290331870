import { useMutation } from '@tanstack/react-query'
import { ICreateArticleCommentRequest } from 'data-transfers/requests'

import { IUseMutation } from 'hooks/types'

import { createArticleComments } from './articleComments.service'

const useCreateArticleComment = (
  options: IUseMutation<void, unknown, ICreateArticleCommentRequest>
) => {
  return useMutation(createArticleComments, options)
}

export default useCreateArticleComment
