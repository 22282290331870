import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'

import { OptionLanguage } from '../../types'

function SelectLanguagesMobileItem({
  id,
  lang,
  onDelete
}: {
  id: string
  lang: OptionLanguage
  onDelete: (label: string) => void
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="z-[100] flex h-[25px] w-fit cursor-pointer touch-none items-center gap-2 bg-gray-900 px-1 text-white"
    >
      <div className={'flex items-center'}>
        <span
          className={clsx(
            'fp fp-md fp-rounded scale-50 ring ring-white',
            lang.code
          )}
        ></span>
        <span className="ml-1 text-sm text-white">{lang.label}</span>
      </div>
      <div
        className="w-fit rotate-45 text-xl"
        onMouseUp={() => onDelete(id)}
        onTouchEnd={() => onDelete(id)}
      >
        +
      </div>
    </div>
  )
}

export default SelectLanguagesMobileItem
