import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { IOrganisation } from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import LangDescriptions from 'components/LangDescriptions'
import { IconArrow } from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import { useSaveOrganisationStep } from 'hooks/useOrganisation'

import { orgStep3Validator } from 'validators/orgStepValidator'
import { getOrganisationDescriptions } from 'utils'

import { IOrgStepProps } from './types'

type IFormContent = Pick<IOrganisation, 'descriptions' | 'countryCode'>
interface IFormDescriptionProps
  extends Omit<IOrgStepProps<IFormContent>, 'onSubmit'> {
  onSubmit?: (vales: Pick<IOrganisation, 'descriptions'>) => void
}
export const FormDescription = ({
  initialValues,
  footer,
  onSubmit
}: IFormDescriptionProps) => {
  const { t } = useTranslation(['inputs', 'actions'])
  const decStep = useOrgNewStore((state) => state.decStep)
  const incStep = useOrgNewStore((state) => state.incStep)
  const setMajorStep = useOrgNewStore((state) => state.setMajorStep)
  const majorStep = useOrgNewStore((state) => state.majorStep)
  const tempOrg = useOrgNewStore((state) => state.tempOrg)

  const actualValues = useMemo(
    () => (initialValues ? initialValues : tempOrg),
    [initialValues, tempOrg]
  )
  const descriptions = useMemo<IFormContent['descriptions']>(() => {
    return getOrganisationDescriptions(
      actualValues?.countryCode,
      actualValues?.descriptions
    )
  }, [actualValues?.countryCode, actualValues?.descriptions])

  const { mutate, isLoading } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.Description,
    { onSuccess: () => incStep(OrganisationOnboardingStatus.SocialNetworks) }
  )

  return (
    <Formik
      initialValues={{
        descriptions
      }}
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(orgStep3Validator)}
      onSubmit={(values) => {
        if (onSubmit) {
          onSubmit(values)
          return
        }

        mutate({ data: values })
      }}
    >
      {({ values }) => (
        <Form noValidate>
          <LangDescriptions descriptions={values.descriptions ?? []} />
          {footer || (
            <div className="flex flex-wrap justify-between gap-4">
              <Button
                onClick={() => {
                  decStep(OrganisationOnboardingStatus.Languages)
                  setMajorStep(majorStep - 1)
                }}
              >
                <span>{t('back', { ns: 'actions' })}</span>
              </Button>
              <Button
                className="animated-svg"
                type="submit"
                isLoading={isLoading}
              >
                <span className=" mr-2">{t('next', { ns: 'actions' })}</span>
                <IconArrow />
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

const OrgStep3 = ({ index, step }: { index: number; step?: string }) => {
  const { t } = useTranslation(['org_form'])
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)
  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
      description={t('description.description')}
    >
      <FormDescription />
    </AccordionItem>
  )
}

export default OrgStep3
