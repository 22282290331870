const isUUID = (val?: string | null) => {
  if (!val) return false

  const regEx = new RegExp(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i
  )

  return regEx.test(val)
}

const checkUtil = {
  isUUID
}

export default checkUtil
