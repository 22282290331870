import { NotificationType } from 'enums'

import AfterPayingProFormaModal from './modals/AfterPayingProFormaModal'
import StartSubscriptionExpiringModal from './modals/StartSubscriptionExpiringModal'
import SubscriptionExpiringModal from './modals/SubscriptionExpiringModal'
import SubscriptionNotificationModal from './modals/SubscriptionNotificationModal'
import { IOrganisationNotificationModalProps } from './types'

const OrganisationNotificationsModal = ({
  notifications,
  isLoading,
  onClose
}: IOrganisationNotificationModalProps) => {
  return (
    <>
      <SubscriptionNotificationModal
        isOpen={notifications === NotificationType.TooManyActiveJobAds}
        isLoading={isLoading}
        onClose={onClose}
      />
      <StartSubscriptionExpiringModal
        isOpen={notifications === NotificationType.StartSubscriptionExpiring}
        isLoading={isLoading}
        onClose={onClose}
      />
      <SubscriptionExpiringModal
        isOpen={notifications === NotificationType.SubscriptionExpiring}
        isLoading={isLoading}
        onClose={onClose}
      />
      <AfterPayingProFormaModal />
    </>
  )
}

export default OrganisationNotificationsModal
