import { useTranslation } from 'next-i18next'

import { Button } from 'components/buttons'
import { IconArrow } from 'icons'

import { IModalFooterProps } from './types'

const ModalFooter = ({ isLoading, onClose }: IModalFooterProps) => {
  const { t } = useTranslation(['actions'])
  return (
    <div className="flex flex-wrap justify-end gap-4">
      <Button onClick={onClose}>
        <span className=" mr-2">{t('cancel', { ns: 'actions' })}</span>
      </Button>
      <Button
        className="animated-svg"
        type="submit"
        isLoading={isLoading}
        isSpinner
      >
        <span className=" mr-2">{t('save', { ns: 'actions' })}</span>
        <IconArrow />
      </Button>
    </div>
  )
}

export default ModalFooter
