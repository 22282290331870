import { useTranslation } from 'next-i18next'

import { Description } from 'validators/descriptionValidator'

import Input from './Fields/Input'

export interface ILangDescriptions {
  name?: string
  descriptions: Description[]
}

const LangDescriptions = ({
  descriptions,
  name = 'descriptions'
}: ILangDescriptions) => {
  const { t } = useTranslation(['inputs'])
  return (
    <>
      {descriptions.map((desc, idx) => (
        <div className="mb-10" key={desc.languageCode}>
          <Input
            as="textarea"
            label={t(`description.${desc.languageCode}.label`, {
              ns: 'inputs'
            })}
            placeholder={t('description.placeholder', { ns: 'inputs' })}
            inputClassName="min-h-[160px]"
            name={`${name}[${idx}].value`}
          />
        </div>
      ))}
    </>
  )
}

export default LangDescriptions
