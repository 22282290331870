import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'

import PaymentTypeRadio from 'components/Fields/PaymentTypeRadio'

import { IPaymentMethodSelectionProps } from '../types'

const PaymentMethodSelection = ({
  paymentType,
  setPaymentType
}: IPaymentMethodSelectionProps) => {
  const { t } = useTranslation()
  return (
    <Formik
      initialValues={{ type: paymentType }}
      onSubmit={() => {
        // idk what to do here, I just want to set paymentType
      }}
    >
      <Form>
        <div className={'mb-10'}>
          <div
            className={
              'w-full pb-5 text-2xl font-semibold tracking-wide text-gray-900'
            }
          >
            {t('payment.description', { ns: 'org_form' })}
          </div>

          <div className={'flex max-w-xl flex-wrap gap-6 text-left text-base'}>
            <PaymentTypeRadio
              name="type"
              value="CreditCard"
              label={t('payment.paymentMethods.CreditCard.title', {
                ns: 'org_form'
              })}
              description={t('payment.paymentMethods.CreditCard.description', {
                ns: 'org_form'
              })}
              onChangeValue={setPaymentType}
            />

            <PaymentTypeRadio
              name="type"
              value="ProForma"
              label={t('payment.paymentMethods.ProForma.title', {
                ns: 'org_form'
              })}
              description={t('payment.paymentMethods.ProForma.description', {
                ns: 'org_form'
              })}
              onChangeValue={setPaymentType}
            />
          </div>
        </div>
      </Form>
    </Formik>
  )
}
export default PaymentMethodSelection
