import React from 'react'
import { components, SingleValueProps } from 'react-select'
import clsx from 'clsx'

import { OptionLanguage } from '../../types'

const SortableValue = (props: SingleValueProps<OptionLanguage>) => {
  return (
    <components.SingleValue
      {...props}
      className="z-[51] bg-gray-900 text-white"
    >
      <div>
        <span
          className={clsx(
            'fp fp-md fp-rounded scale-50 ring ring-white',
            props.data?.code
          )}
        ></span>
        <span className="ml-2 font-medium text-white">{props.data.label}</span>
      </div>
    </components.SingleValue>
  )
}

export default SortableValue
