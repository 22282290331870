import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { IOrganisation } from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import Dropzone from 'components/Dropzone'
import ImageEditor from 'components/ImageEditor'
import { IconArrow, IconCompany } from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import { postImage } from 'hooks/useImage'
import { useSaveOrganisationStep } from 'hooks/useOrganisation'

import { orgStep5Validator } from 'validators/orgStepValidator'
import dataURItoBlob from 'utils/dataURIToBlob'
import { getZodMessage } from 'utils/validation.util'

import { IOrgStepProps } from './types'

type IFormContent = Pick<IOrganisation, 'mainPictureUrl'>
export const FormCompanyPicture = ({
  initialValues,
  footer,
  onSubmit
}: IOrgStepProps<IFormContent>) => {
  const { t } = useTranslation(['org_form', 'actions'])
  const [isLoading, setIsLoading] = useState(false)
  const formikRef = useRef<any>(null)
  const incStep = useOrgNewStore((state) => state.incStep)
  const decStep = useOrgNewStore((state) => state.decStep)
  const { data: session } = useSession()
  const jwt = session?.jwt
  const tempOrg = useOrgNewStore((state) => state.tempOrg)

  const currentOrg = useMemo<IFormContent>(() => {
    if (initialValues?.mainPictureUrl) {
      return {
        mainPictureUrl: initialValues.mainPictureUrl
      }
    }
    return { mainPictureUrl: tempOrg?.mainPictureUrl ?? '' }
  }, [initialValues, tempOrg?.mainPictureUrl])

  const ref = useRef<AvatarEditor>(null)
  const [image, setImage] = useState<File | string | null>(
    currentOrg.mainPictureUrl
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    noKeyboard: true,
    noClick: false,
    maxSize: 21000000,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        handleImage(acceptedFiles[0], formikRef?.current?.setErrors)
      }
    }
  })

  const handleImage = useCallback(
    (img: File | null, setErrors?: (error: any) => void) => {
      setImage(img)
      if (setErrors) {
        setErrors({})
      }
    },
    []
  )

  const { mutate, isLoading: isLoadingOrganisation } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.Photo
  )

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ image: currentOrg.mainPictureUrl }}
      validationSchema={toFormikValidationSchema(orgStep5Validator)}
      onSubmit={async (_v, helper) => {
        if (ref.current) {
          setIsLoading(true)
          // TODO: might pose issues if uploading through mobile
          try {
            const file = dataURItoBlob(
              ref.current.getImageScaledToCanvas().toDataURL()
            )
            if (file && jwt) {
              const mainPictureUrl = await postImage({
                file,
                jwt
              })
              if (!mainPictureUrl) {
                return
              }
              if (onSubmit) {
                onSubmit({ mainPictureUrl })
                return
              }

              mutate({ data: { mainPictureUrl } })
            } else {
              helper.setErrors({
                image: t('companyPhoto.error', { ns: 'org_form' })
              })
              return
            }
          } catch (e) {
            incStep(OrganisationOnboardingStatus.Gallery)
            return
          }
        } else {
          helper.setErrors({
            image: t('companyPhoto.error', { ns: 'org_form' })
          })
          return
        }
        incStep(OrganisationOnboardingStatus.Gallery)
        setIsLoading(false)
      }}
    >
      {({ setErrors, errors, isSubmitting }) => (
        <Form>
          <div className="mt-5 flex flex-col justify-center overflow-x-hidden">
            <div className="mb-10 h-auto overflow-hidden">
              {image && (
                <ImageEditor
                  ref={ref}
                  image={image}
                  border={0}
                  borderRadius={0}
                  height={450}
                  defaultScale={'0'}
                  width={1150}
                  editorClassName="border-4 border-black overflow-x-hidden"
                  handleImage={(img) => handleImage(img, setErrors)}
                  style={{ width: '100%', height: 'auto' }}
                  crossOrigin={'anonymous'}
                />
              )}
              {!image && (
                <Dropzone
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  width={-1}
                  className="mx-auto h-60 w-full"
                >
                  <span className="mx-auto h-24 w-24">
                    <IconCompany />
                  </span>
                  <span className="block pt-5">
                    {t('photoForm.fileButton', { ns: 'org_form' })}
                  </span>
                </Dropzone>
              )}
            </div>

            {typeof errors.image === 'string' && (
              <span className="fade-in font-medium tracking-wide text-error-400">
                {getZodMessage(errors.image, t('zod:' + errors.image))}
              </span>
            )}

            {footer || (
              <div className="mt-4 flex items-center justify-between">
                <Button
                  onClick={() => decStep(OrganisationOnboardingStatus.Logo)}
                >
                  <span>{t('back', { ns: 'actions' })}</span>
                </Button>
                <div className="flex items-center justify-end gap-4">
                  <Button
                    className="animated-svg"
                    type="submit"
                    isLoading={
                      isSubmitting || isLoading || isLoadingOrganisation
                    }
                  >
                    <span className=" mr-2">
                      {t('next', { ns: 'actions' })}
                    </span>
                    <IconArrow />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

const OrgStep6 = ({ index, step }: { index: number; step?: string }) => {
  const { t } = useTranslation(['org_form'])
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)

  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
      description={t('companyPhoto.description', { ns: 'org_form' })}
    >
      <FormCompanyPicture />
    </AccordionItem>
  )
}

export default OrgStep6
