import { useState } from 'react'
import { asLink, isFilled } from '@prismicio/helpers'

import { Button } from 'components/buttons'

import { linkResolver } from 'env/prismicio'

import InformationModal from '../InformationModal'

import { IAdvertisementBannerModal } from './types'

const AdvertisementBannerModal = ({ doc }: IAdvertisementBannerModal) => {
  const [isOpen, setIsOpen] = useState(true)

  if (!isFilled.contentRelationship(doc) || !doc.data?.visibility) {
    return null
  }

  return (
    <InformationModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={doc.data.title}
      titleVariant="h2"
      subtitle={doc.data?.text}
      className="whitespace-pre-wrap text-center"
    >
      <Button
        button
        href={asLink(doc.data.linkto, linkResolver)}
        className="mt-5"
      >
        {doc.data.buttontext}
      </Button>
    </InformationModal>
  )
}

export default AdvertisementBannerModal
