import { FC, useEffect, useMemo, useState } from 'react'

import ConfirmationModal from '../ConfirmationModal'
import { IConfirmationModalProps } from '../ConfirmationModal/types'

import { CloseJobAd, CopyJobAd, UpgradeToPro } from './components'
import { IProjectHeaderModals, ProjectModalType } from './types'

const modals: Record<ProjectModalType | 'empty', FC> = {
  copyAdvert: CopyJobAd,
  closeAdvert: CloseJobAd,
  upgradeToPro: UpgradeToPro,
  empty: () => null
}

const ProjectHeaderModals = ({ isOpen, ...props }: IProjectHeaderModals) => {
  const [previousType, setPreviousType] = useState<ProjectModalType | 'empty'>(
    'empty'
  )
  useEffect(() => {
    if (isOpen) {
      setPreviousType(isOpen)
    }
  }, [isOpen])

  const tSubmit = useMemo<IConfirmationModalProps['tSubmit']>(() => {
    if (isOpen === 'upgradeToPro' || previousType === 'upgradeToPro') {
      return { key: 'upgrade', ns: 'actions' }
    }
    if (isOpen === 'closeAdvert' || previousType === 'closeAdvert') {
      return { key: 'projects.modals.closeAdvert.submit', ns: 'crm' }
    }
    return
  }, [isOpen, previousType])

  const Content = modals[isOpen ?? previousType]
  return (
    <ConfirmationModal isOpen={!!isOpen} tSubmit={tSubmit} {...props}>
      <Content />
    </ConfirmationModal>
  )
}

export default ProjectHeaderModals
