import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IUseMutation } from 'hooks/types'

import { subscriptionsKeys } from './keys'
import { cancelOrganisationSubscription } from './subscription.service'

const useCancelOrganisationSubscription = ({
  onSuccess,
  ...options
}: IUseMutation<void, unknown, void> = {}) => {
  const queryClient = useQueryClient()
  return useMutation(cancelOrganisationSubscription, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        subscriptionsKeys.organisationSubscriptions()
      )
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useCancelOrganisationSubscription
