import { useQuery } from '@tanstack/react-query'
import { IArticleComment } from 'data-transfers/dto'
import { IGetArticleCommentsRequest } from 'data-transfers/requests'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { getArticleComments } from './articleComments.service'
import { articleCommentsKeys } from './keys'

const useArticleComments = ({
  payload,
  options
}: IUseQueryHookWithPayload<IGetArticleCommentsRequest, IArticleComment[]>) => {
  return useQuery(
    articleCommentsKeys.articleComments(payload),
    async () => {
      const response = await getArticleComments(payload)
      return response
    },
    options
  )
}

export default useArticleComments
