import { useMutation } from '@tanstack/react-query'

import { CandidateIndustries } from 'components/modals/CandidateNotificationsModal/modals/FillUserIndustriesNotification/types'

import { IUseMutation } from 'hooks/types'

import { sendUserIndustries } from './cvCreator.service'

const useUpdateCandidateIndustries = ({
  ...options
}: IUseMutation<CandidateIndustries, unknown, CandidateIndustries>) => {
  return useMutation(sendUserIndustries, {
    ...options
  })
}

export default useUpdateCandidateIndustries
