import { useQuery } from '@tanstack/react-query'
import { IAvailableSubscriptionsResponse } from 'data-transfers/responses'

import { IUseQueryHook } from 'hooks/types'

import { subscriptionsKeys } from './keys'
import { getAvailableSubscriptions } from './subscription.service'

const useAvailableSubscriptions = ({
  options
}: IUseQueryHook<never, IAvailableSubscriptionsResponse>) => {
  return useQuery<IAvailableSubscriptionsResponse>(
    subscriptionsKeys.availableSubscriptions(),
    async () => {
      const data = await getAvailableSubscriptions()
      return data
    },
    options
  )
}

export default useAvailableSubscriptions
