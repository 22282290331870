import { SliceZone } from '@prismicio/types'
import create from 'zustand'

import { PricingBulletsSlice } from 'types/prismic'
import { IMappedPricing } from 'utils'

export interface AdvertPublicStore {
  planSlice?: SliceZone<PricingBulletsSlice>
  mappedPricing: IMappedPricing | null
  setPlans: (planSlice: SliceZone<PricingBulletsSlice>) => void
  setPrices: (mappedPricing: IMappedPricing) => void
  clear: () => void
}

const useSubscriptionPlansStore = create<AdvertPublicStore>()((set) => ({
  planSlice: [],
  mappedPricing: null,
  setPlans: (planSlice: SliceZone<PricingBulletsSlice>) =>
    set((state) => ({ ...state, planSlice })),
  setPrices: (mappedPricing: IMappedPricing) => {
    set((state) => ({ ...state, mappedPricing }))
  },
  clear: () => set({ planSlice: [], mappedPricing: null })
}))

export default useSubscriptionPlansStore
