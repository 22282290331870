import FormChangeEmail from 'components/Forms/FormChangeEmail'

import IVerifyChangeEmail from 'validators/changeEmailValidator'

import Modal from '../Modal'
import { IModal } from '../types'

import useChangeEmailModal from './useChangeEmailModal'

const ChangeEmailModal = ({
  isOpen,
  onClose,
  email
}: IModal & Partial<IVerifyChangeEmail>) => {
  const { isLoading, onSubmit } = useChangeEmailModal({ onClose })
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className="my-10 mx-2 text-lg text-gray-500 sm:mx-8 md:mx-16">
        <FormChangeEmail
          initialEmail={email}
          isLoading={isLoading}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </div>
    </Modal>
  )
}

export default ChangeEmailModal
