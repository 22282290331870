import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { ErrorMessage, Field } from 'formik'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import { getZodMessage } from 'utils/validation.util'

import { InputProps, InputStyle } from './Input'

const InputRadio = ({
  label,
  name,
  required,
  className,
  onChange,
  ...props
}: Pick<
  InputProps,
  | 'name'
  | 'label'
  | 'className'
  | 'disabled'
  | 'required'
  | 'value'
  | 'onChange'
>) => {
  const { t } = useTranslation(['zod'])
  z.setErrorMap(makeZodI18nMap(t))

  return (
    <div className={clsx(InputStyle.fieldWrap, className)}>
      <label className={'flex cursor-pointer items-center gap-2'}>
        <span className={'text-lg font-semibold tracking-wide text-gray-900'}>
          {label}
          {required && <span className={InputStyle.required}>*</span>}
        </span>
        <Field
          name={name}
          type={'radio'}
          required={required}
          onChange={onChange}
          {...props}
        />
      </label>
      <div className={InputStyle.info}>
        <ErrorMessage
          name={name}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="fade-in text-medium text-error-400">
                {getZodMessage(msg, t(msg, { ns: 'zod' }))}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default InputRadio
