import { useMutation } from '@tanstack/react-query'

import { IUseMutation } from 'hooks/types'

import IVerifyChangeEmail from 'validators/changeEmailValidator'

import { verifyChangeEmail } from './profile.service'

const useVerifyChangeEmail = (
  options: IUseMutation<void, unknown, IVerifyChangeEmail>
) => {
  return useMutation(verifyChangeEmail, options)
}

export default useVerifyChangeEmail
