import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { IOrganisation } from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import Input from 'components/Fields/Input'
import {
  IconArrow,
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTiktok,
  IconTwitter
} from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import { useSaveOrganisationStep } from 'hooks/useOrganisation'

import { orgStep4Validator } from 'validators/orgStepValidator'

import { IOrgStepProps } from './types'

const SOCIAL_NETWORKS = [
  'linkedin',
  'facebook',
  'twitter',
  'instagram',
  'tiktok'
]
type IFormContent = Pick<IOrganisation, 'socials'>
export const FormSocialNetworks = ({
  initialValues,
  footer,
  onSubmit
}: IOrgStepProps<IFormContent>) => {
  const { t } = useTranslation(['actions'])
  const incStep = useOrgNewStore((state) => state.incStep)
  const decStep = useOrgNewStore((state) => state.decStep)
  const tempOrg = useOrgNewStore((state) => state.tempOrg)
  const currentOrg = useMemo<IFormContent>(() => {
    const socials = initialValues ? initialValues?.socials : tempOrg?.socials

    return {
      socials: SOCIAL_NETWORKS.map((type) => ({
        type,
        url: socials?.find((social) => social.type === type)?.url ?? ''
      }))
    }
  }, [initialValues, tempOrg?.socials])

  const { mutate, isLoading } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.SocialNetworks,
    { onSuccess: () => incStep(OrganisationOnboardingStatus.Logo) }
  )

  return (
    <Formik
      initialValues={currentOrg}
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(orgStep4Validator)}
      onSubmit={(values) => {
        if (onSubmit) {
          onSubmit(values)
          return
        }
        const socials = values.socials.filter(({ url }) => url)

        mutate({ data: { socials } })
      }}
    >
      <Form>
        <div
          className={
            'mb-5 grid grid-cols-1 gap-x-5 gap-y-4  md:grid-cols-2 lg:grid-cols-3'
          }
        >
          <Input
            label={
              <span className="flex items-center">
                <IconLinkedin className="mr-2  h-6 w-6" />
                <span>LinkedIn</span>
              </span>
            }
            name="socials[0].url"
            placeholder="username"
          />
          <Input
            label={
              <span className="flex items-center">
                <IconFacebook className="mr-2  h-6 w-6" />
                <span>Facebook</span>
              </span>
            }
            name="socials[1].url"
            placeholder="username"
          />
          <Input
            label={
              <span className="flex items-center">
                <IconTwitter className="mr-2  h-6 w-6" />
                <span>X</span>
              </span>
            }
            name="socials[2].url"
            placeholder="username"
          />
          <Input
            label={
              <span className="flex items-center">
                <IconInstagram className="mr-2  h-6 w-6" />
                <span>Instagram</span>
              </span>
            }
            name="socials[3].url"
            placeholder="username"
          />
          <Input
            label={
              <span className="flex items-center">
                <IconTiktok className="mr-2  h-6 w-6" />
                <span>TikTok</span>
              </span>
            }
            name="socials[4].url"
            placeholder="username"
          />
        </div>
        {footer || (
          <div className="flex flex-wrap justify-between gap-4">
            <Button
              onClick={() => decStep(OrganisationOnboardingStatus.Description)}
            >
              <span>{t('back', { ns: 'actions' })}</span>
            </Button>
            <Button
              className="animated-svg"
              type="submit"
              isLoading={isLoading}
            >
              <span className=" mr-2">{t('next', { ns: 'actions' })}</span>
              <IconArrow />
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  )
}

const OrgStep4 = ({ index, step }: { index: number; step?: string }) => {
  const { t } = useTranslation(['org_form'])
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)
  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
      description={t('socialNetworks.description', { ns: 'org_form' })}
    >
      <FormSocialNetworks />
    </AccordionItem>
  )
}

export default OrgStep4
