import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { authClient } from 'utils'

const removeUserAccount = async () => {
  await authClient.delete('/user-profile')
  return
}

export const useRemoveUserAccount = (options: UseMutationOptions) => {
  return useMutation(removeUserAccount, options)
}
