import ConfirmationModal from '../ConfirmationModal'
import { IModal } from '../types'

import useRemoveAccountModal from './useRemoveAccountModal'

const RemoveAccountModal = ({ onClose, isOpen }: IModal) => {
  const { t, isLoading, mutate } = useRemoveAccountModal({ onClose })
  return (
    <ConfirmationModal
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={mutate}
      tSubmit={{ key: 'removeAccount.btnLabel', ns: 'userProfile' }}
      buttonsClassName="flex flex-wrap gap-y-2"
    >
      <div>{t('removeAccount.title1', { ns: 'userProfile' })}</div>
      <div>{t('removeAccount.title2', { ns: 'userProfile' })}</div>
    </ConfirmationModal>
  )
}

export default RemoveAccountModal
