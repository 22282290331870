import clsx from 'clsx'
import { useFormikContext } from 'formik'

import InputRadio from './InputRadio'

export type IPaymentType = 'CreditCard' | 'ProForma'

interface PaymentTypeRadioProps {
  name: string
  value: IPaymentType
  label: string
  description: string
  onChangeValue?: (type: IPaymentType) => void
}

const PaymentTypeRadio = ({
  name,
  label,
  description,
  value,
  onChangeValue
}: PaymentTypeRadioProps) => {
  const { values, setFieldValue } = useFormikContext<{
    paymentMethod: IPaymentType
  }>()

  return (
    <div
      onClick={() => {
        setFieldValue(name, value)
        onChangeValue?.(value)
      }}
      className={clsx(
        'flex w-full cursor-pointer flex-col items-start gap-4 border-2 p-5 hover:border-black',
        values.paymentMethod == value ? 'border-black' : 'border-gray-400'
      )}
    >
      <InputRadio
        name={name}
        label={label}
        value={value}
        className={
          'flex flex-row-reverse items-center gap-x-2 whitespace-nowrap [&>label]:pt-1 [&>label]:pb-0'
        }
        onChange={(e) => {
          setFieldValue(name, e.target.value)
          onChangeValue?.(e.target.value as IPaymentType)
        }}
      />

      <div>{description}</div>
    </div>
  )
}

export default PaymentTypeRadio
