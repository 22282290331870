import { useTranslation } from 'next-i18next'

import ConfirmationModal from '../ConfirmationModal'

import { IDeleteJobAdDraftModalProps } from './types'

const DeleteJobAdDraftModal = (props: IDeleteJobAdDraftModalProps) => {
  const { t } = useTranslation(['crm'])
  return (
    <ConfirmationModal {...props}>
      <div className="my-2 text-center text-xl font-semibold text-black sm:text-3xl">
        {t('deleteJobAdModal', { ns: 'crm' })}
      </div>
    </ConfirmationModal>
  )
}
export default DeleteJobAdDraftModal
