import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'

import { IButtonTranslation, IConfirmationModalProps } from './types'

const getTranslations = (resource: string | IButtonTranslation) => {
  let ns = 'actions'

  if (typeof resource === 'string') {
    return { key: resource, ns }
  }

  ns = resource.ns
  return { key: resource.key, ns }
}

const useConfirmationModal = ({
  tSubmit,
  tCancel,
  onCancel,
  onClose
}: Omit<IConfirmationModalProps, 'isLoading' | 'isOpen'>) => {
  const { t } = useTranslation('actions')

  const submitLabel = useMemo(() => {
    if (!tSubmit) {
      return ''
    }
    const { key, ns } = getTranslations(tSubmit)
    return t(key, { ns })
  }, [t, tSubmit])

  const cancelLabel = useMemo(() => {
    if (!tCancel) {
      return ''
    }
    const { key, ns } = getTranslations(tCancel)
    return t(key, { ns })
  }, [t, tCancel])

  const onDenial = useCallback(() => {
    if (onCancel) {
      onCancel()
      return
    }
    onClose?.()
  }, [onCancel, onClose])
  return { onDenial, submitLabel, cancelLabel }
}

export default useConfirmationModal
