import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'
import Button from 'components/buttons/Button'
import InformationModal from 'components/modals/InformationModal'
import { IModal } from 'components/modals/types'
import { IconArrow } from 'icons'
import useUpdateCandidateIndustries from 'hooks/useCVCreator/useUpdateCandidateIndustries'
import SelectListItemMulti from 'components/Fields/SelectListItemMulti'
import { CandidateIndustries } from './types'

const FillUserIndustriesNotificationModal = ({
  isOpen,
  isLoading,
  onClose
}: IModal & { isLoading?: boolean }) => {
  const { t } = useTranslation(['crm', 'actions'])
  const { data: session } = useSession()
  const { mutate } = useUpdateCandidateIndustries({ onSuccess: onClose })
  const userId = session?.user?.userId as string

  return (
    <InformationModal
      className="max-w-[35rem]"
      isOpen={isOpen}
      isLoading={isLoading}
      title={t('candidate.modals.fillIndustries.title', { ns: 'crm' })}
      titleVariant={'h2'}
      subtitle={t('candidate.modals.fillIndustries.content', { ns: 'crm' })}
      textClassName={'w-full'}
      crossClassName={'hidden'}
    >
      <Formik
        initialValues={{ industries: [] }}
        onSubmit={(values) => {
          const candidateIndustries: CandidateIndustries = {
            industries: values.industries,
            candidateId: userId
          }
          mutate(candidateIndustries)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <SelectListItemMulti
              type="Industry"
              name="industries"
              placeholder={t('defaults.placeholderSelect', { ns: 'inputs' })}
              required
              labelTooltip={t('industryMultiple.tooltip', { ns: 'inputs' })}
            />

            <div className="flex gap-4 pt-[5%]  justify-center">
              <Button
                className="animated-svg"
                type="submit"
                isLoading={isLoading || isSubmitting}
                disabled={
                  isLoading || isSubmitting || values.industries.length === 0
                }
              >
                <span className="mr-2">{t('save', { ns: 'actions' })}</span>
                <IconArrow />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </InformationModal>
  )
}

export default FillUserIndustriesNotificationModal
