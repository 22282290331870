import { useTranslation } from 'next-i18next'

import InformationModal from 'components/modals/InformationModal'
import { IDefaultModal } from 'components/modals/types'

const PhoneNumberVisibleModal = ({
  isOpen,
  isLoading,
  onClose
}: IDefaultModal) => {
  const { t } = useTranslation(['crm'])

  return (
    <InformationModal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={t('candidate.modals.phoneVisibleOnCV.title', {
        ns: 'crm'
      })}
      titleVariant={'h2'}
    >
      <div>
        {t('candidate.modals.phoneVisibleOnCV.content', {
          ns: 'crm'
        })}
      </div>
    </InformationModal>
  )
}

export default PhoneNumberVisibleModal
