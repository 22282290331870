import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useMemo, useState } from 'react'
import { MultiValue, OnChangeValue } from 'react-select'
import { SortEndHandler } from 'react-sortable-hoc'
import { useField } from 'formik'

import { useDocument } from 'hooks/useDocument'

import { languagesDict } from 'data/languages'
import { ILocale } from 'types/app'
import SelectStyles from 'utils/SelectStyle'
import { allLocales } from 'constants/locales'

import { OptionLanguage } from '../types'

const useSelectLanguagesDesktop = (name: string) => {
  const SelectStyle = useMemo(() => SelectStyles(), [])
  const { t } = useTranslation('zod')
  const { locale } = useRouter()
  const document = useDocument()

  const [field, , { setValue, setTouched }] =
    useField<MultiValue<OptionLanguage>>(name)

  const options = useMemo(() => {
    const translate: ILocale =
      (allLocales.find((i) => i === locale) as ILocale) ?? 'en'

    return languagesDict[translate].map((item) => ({
      ...item,
      language: item.name,
      value: item.name
    }))
  }, [locale])

  const [selected, setSelected] = useState<MultiValue<OptionLanguage>>(
    options.filter((item) =>
      field.value.map((i) => i.language).includes(item.language)
    )
  )

  function arrayMove<T>(array: readonly T[], from: number, to: number) {
    const slicedArray = array.slice()
    slicedArray.splice(
      to < 0 ? array.length + to : to,
      0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      slicedArray.splice(from, 1)[0]
    )
    return slicedArray
  }

  const onChange = (selectedOptions: OnChangeValue<OptionLanguage, true>) => {
    setValue(selectedOptions)
    setSelected(selectedOptions)
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(selected, oldIndex, newIndex)
    setSelected(newArray)
    setValue(newArray)
  }

  return {
    onChange,
    onSortEnd,
    options,
    selected,
    setTouched,
    SelectStyle,
    document,
    t
  }
}

export default useSelectLanguagesDesktop
