import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import ConfirmationModal from '../ConfirmationModal'

import { IImmediatelyChangeSubscriptionProps } from './types'

const ImmediatelyChangeSubscription = ({
  isOpen,
  onClose,
  onSubmit
}: IImmediatelyChangeSubscriptionProps) => {
  const { t } = useTranslation(['crm'])
  const onSubmitHandler = useCallback(() => {
    onSubmit?.()
    onClose?.()
  }, [onClose, onSubmit])
  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmitHandler}
      tSubmit="change"
      tCancel="decline"
      buttonsClassName=" justify-between"
    >
      {t('org.subscription.confirmChange', { ns: 'crm' })}
    </ConfirmationModal>
  )
}

export default ImmediatelyChangeSubscription
