import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { useRemoveOrganisationEmployee } from 'hooks/useUserManagement'

import { IRemoveOrganisationEmployeeModalProps } from './types'

const useRemoveOrganisationEmployeeModal = ({
  removeUser,
  onClose
}: IRemoveOrganisationEmployeeModalProps) => {
  const { t } = useTranslation(['actions', 'crm'])

  const { mutate, isLoading } = useRemoveOrganisationEmployee({
    onSuccess: () => {
      onClose?.()
    },
    onError: () => {
      toast.error(t('org.toast.cannotRemoveUser', { ns: 'crm' }))
      onClose?.()
    }
  })
  const removeOrganisationEmployee = useCallback(() => {
    if (!removeUser) {
      return
    }
    mutate({ organisationEmployeeId: removeUser })
  }, [mutate, removeUser])

  return { removeOrganisationEmployee, t, isLoading }
}

export default useRemoveOrganisationEmployeeModal
