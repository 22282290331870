import React from 'react'
import { components, OptionProps } from 'react-select'

import { OptionLanguage } from '../../types'

const Option = (props: OptionProps<OptionLanguage>) => {
  return (
    <components.Option {...props}>
      <i className={`fp fp-md fp-rounded ring ring-white ${props.data.code}`} />
      <span className="ml-2">{props.data.label}</span>
    </components.Option>
  )
}

export default Option
