import IconMenu from 'components/MenuIcon'
import ConfirmationModal from 'components/modals/ConfirmationModal'

import usePageLeave from './usePageLeave'

const LeaveJobCreationPageModal = () => {
  const {
    isPageLeave,
    handleSetLeave,
    handleConfirmLeavePage,
    isLoading,
    isSuccess,
    t
  } = usePageLeave()

  return (
    <ConfirmationModal
      isOpen={isPageLeave}
      onClose={handleSetLeave(false)}
      onSubmit={() => handleConfirmLeavePage(true)}
      onCancel={() => handleConfirmLeavePage(false)}
      tSubmit={{ key: 'yes', ns: 'actions' }}
      tCancel={{ key: 'no', ns: 'actions' }}
      isLoading={isLoading}
      disabled={isLoading || isSuccess}
    >
      <div className="relative">
        <div className="absolute top-[-30px] right-[-30px] md:right-[-55px]">
          <IconMenu isOpen={true} onClick={handleSetLeave(false)} />
        </div>

        <h4 className="my-2 text-center text-xl font-semibold text-black sm:text-3xl">
          {t('toast.jobAdPageLeaveModal', { ns: 'ad' })}
        </h4>
      </div>
    </ConfirmationModal>
  )
}

export default LeaveJobCreationPageModal
