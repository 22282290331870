import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import clsx from 'clsx'
import { IOrganisation } from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import { IconArrow } from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import useMediaQuery from 'hooks/useMediaQuery'
import { useSaveOrganisationStep } from 'hooks/useOrganisation'

import { orgStep2Validator } from 'validators/orgStepValidator'
import { minWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

import {
  SelectLanguagesDesktop,
  SelectLanguagesMobile
} from '../Fields/SelectLanguages'

import { IOrgStepProps } from './types'

type IFormContent = Pick<IOrganisation, 'languages'>

export const FormLanguages = ({
  initialValues,
  footer,
  onSubmit
}: IOrgStepProps<IFormContent>) => {
  const isDesktop = useMediaQuery(minWidth(RESPONSIVE.lg))
  const { t } = useTranslation(['inputs', 'actions'])
  const incStep = useOrgNewStore((state) => state.incStep)
  const decStep = useOrgNewStore((state) => state.decStep)
  const tempOrg = useOrgNewStore((state) => state.tempOrg)
  const majorStep = useOrgNewStore((state) => state.majorStep)
  const setMajorStep = useOrgNewStore((state) => state.setMajorStep)
  const currentValues = useMemo<IFormContent>(() => {
    const languages = initialValues
      ? initialValues.languages
      : tempOrg?.languages

    return {
      languages: languages ?? []
    }
  }, [initialValues, tempOrg?.languages])

  const { mutate, isLoading } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.Languages,
    {
      onSuccess: () => {
        incStep(OrganisationOnboardingStatus.Description)
        setMajorStep(majorStep + 1)
      }
    }
  )

  return (
    <Formik
      initialValues={currentValues}
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(orgStep2Validator)}
      onSubmit={(values) => {
        if (onSubmit) {
          onSubmit(values)
          return
        }
        const data = orgStep2Validator.parse(values)

        mutate({
          data: {
            languages: data.languages.map((language) => language.language)
          }
        })
      }}
    >
      <Form>
        <div className={clsx('mb-10 grid grid-cols-1 gap-x-5 gap-y-4')}>
          {isDesktop ? (
            <SelectLanguagesDesktop
              name={`languages`}
              placeholder={t('languages.placeholder', { ns: 'inputs' })}
              helperText={t('languages.helperText', { ns: 'inputs' })}
              className={'hidden lg:block'}
            />
          ) : (
            <SelectLanguagesMobile
              name={`languages`}
              placeholder={t('languages.placeholder', { ns: 'inputs' })}
              helperText={t('languages.helperText', { ns: 'inputs' })}
              className={'block lg:hidden'}
            />
          )}
        </div>
        {footer || (
          <div className="flex flex-wrap justify-between gap-4">
            <Button
              onClick={() =>
                decStep(OrganisationOnboardingStatus.ShowInformation)
              }
            >
              <span>{t('back', { ns: 'actions' })}</span>
            </Button>
            <Button
              className="animated-svg"
              type="submit"
              isLoading={isLoading}
            >
              <span className=" mr-2">{t('next', { ns: 'actions' })}</span>
              <IconArrow />
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  )
}

const OrgStep2 = ({ index, step }: { index: number; step?: string }) => {
  const { t } = useTranslation(['org_form'])
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)

  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
      description={t('officialLanguages.description', { ns: 'org_form' })}
    >
      <FormLanguages />
    </AccordionItem>
  )
}

export default OrgStep2
