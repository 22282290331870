import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import {
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useField } from 'formik'

import { languagesDict } from 'data/languages'
import { ILocale } from 'types/app'
import { allLocales } from 'constants/locales'

import { OptionLanguage } from '../types'

const useSelectLanguagesMobile = (name: string) => {
  const { locale } = useRouter()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const options = useMemo(() => {
    const translate: ILocale = allLocales.find((i) => i === locale) ?? 'en'

    return languagesDict[translate].map((item) => ({
      ...item,
      language: item.name,
      value: item.name
    }))
  }, [locale])

  const [field, , { setValue }] = useField(name)

  const [items, setItems] = useState<string[]>(
    field.value
      .map((item: OptionLanguage) =>
        options.find((option) => option.language === item.language)
      )
      .map((i: OptionLanguage) => i.label)
  )
  const [countriesSelect, setCountriesSelect] = useState(
    options.filter((item) => !items.includes(item.label))
  )

  const isSingleValue = (
    option: SingleValue<OptionLanguage> | MultiValue<OptionLanguage>
  ): option is SingleValue<OptionLanguage> => {
    return !Array.isArray(option)
  }

  const onChange = (
    selectedOption: SingleValue<OptionLanguage> | MultiValue<OptionLanguage>
  ) => {
    if (
      selectedOption &&
      isSingleValue(selectedOption) &&
      !items.includes(selectedOption.label)
    ) {
      if (items.length === 0) {
        setValue(
          options.filter((option) => option.label === selectedOption.label)
        )
      } else {
        setValue(
          options.filter((option) =>
            items.some(
              (item) =>
                item === option.label || option.label === selectedOption.label
            )
          )
        )
      }
      setItems([...items, selectedOption.label])

      const index = countriesSelect.findIndex(
        (item) => item.label === selectedOption.label
      )
      if (index > -1) {
        const mas = countriesSelect
        mas.splice(index, 1)
        setCountriesSelect(mas)
      }
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const oldIndex = items.indexOf(active.id as string)
      const newIndex = items.indexOf(over?.id as string)

      const sortedItems = arrayMove(items, oldIndex, newIndex)
      setValue(
        sortedItems.map((item) =>
          options.find((option) => option.label === item)
        )
      )

      setItems(sortedItems)
    }
  }

  const onDelete = (label: string) => {
    const newArray = items.filter((item) => item !== label)
    setItems(newArray)

    setValue(
      options.filter((item) => newArray.some((i: string) => i === item.label))
    )
    setCountriesSelect([
      ...options.filter((item) => item.label === label),
      ...countriesSelect
    ])
  }

  return {
    onChange,
    onDelete,
    handleDragEnd,
    items,
    sensors,
    options,
    countriesSelect
  }
}

export default useSelectLanguagesMobile
