import { useQuery } from '@tanstack/react-query'
import { ISubscriptionPriceRequest } from 'data-transfers/requests'
import { ISubscriptionPriceResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { subscriptionsKeys } from './keys'
import { getSubscriptionsPrice } from './subscription.service'

const useSubscriptionPrice = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  ISubscriptionPriceRequest,
  ISubscriptionPriceResponse
>) => {
  return useQuery<ISubscriptionPriceResponse>(
    subscriptionsKeys.subscriptionPrice(payload),
    async () => {
      const data = await getSubscriptionsPrice(payload)
      return data
    },
    options
  )
}

export default useSubscriptionPrice
