import { Trans } from 'next-i18next'
import { AdvertTranslation } from 'enums'

import { StyledText } from 'components/AdvertsPage'
import { Button } from 'components/buttons'
import ModalDialog from 'components/modals/Modal'
import { IconArrow } from 'icons'

import { IModal } from '../types'

import useSelectLanguageModal from './useSelectLanguageModal'

const SelectLanguageModal = ({ isOpen, onClose }: IModal) => {
  const { org, countryCode, handleSelectLang, t } = useSelectLanguageModal({
    isOpen,
    onClose
  })

  if (org === null || countryCode !== 'LT') {
    return null
  }

  return (
    <ModalDialog isOpen={isOpen}>
      <div className="max-w-7xl px-6 py-10">
        <h4 className="pb-2 text-center text-xl font-semibold sm:text-3xl">
          {t('selectLanguage.title', { ns: 'ad_form' })}
        </h4>
        <div className="mt-5 grid grid-cols-1 gap-y-2 gap-x-5 md:grid-cols-3">
          <Button onClick={handleSelectLang(AdvertTranslation.English)}>
            <>
              <span className="fp fp-rounded en mr-2"></span>
              {t('selectLanguage.en', { ns: 'ad_form' })}
              <span className="ml-2">
                <IconArrow />
              </span>
            </>
          </Button>
          <Button onClick={handleSelectLang(AdvertTranslation.Lithuanian)}>
            <>
              <span className="fp fp-rounded lt mr-2"></span>
              {t('selectLanguage.lt', { ns: 'ad_form' })}
              <span className="ml-2">
                <IconArrow />
              </span>
            </>
          </Button>
          <Button
            onClick={handleSelectLang(AdvertTranslation.EnglishLithuanian)}
          >
            <>
              <div className="mr-2 flex">
                <span className="fp fp-rounded en mr-1"></span>
                <span className="fp fp-rounded lt mr-1"></span>
              </div>
              {t('selectLanguage.both', { ns: 'ad_form' })}
              <span className="ml-2">
                <IconArrow />
              </span>
            </>
          </Button>
        </div>
        <div className="mt-5 pl-6 font-semibold">
          <Trans
            i18nKey={'selectLanguage.note'}
            t={t}
            ns={'ad_form'}
            components={{
              text: <StyledText className={'block pl-3 font-normal'} />
            }}
          />
        </div>
      </div>
    </ModalDialog>
  )
}

export default SelectLanguageModal
