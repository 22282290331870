import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { useCancelOrganisationSubscription } from 'hooks/useSubscriptions'

import { handleQueryError } from 'utils/queryErrorHandler'

import { IModal } from '../types'

const useCancelSubscriptionModal = ({ onClose }: Pick<IModal, 'onClose'>) => {
  const { t } = useTranslation(['crm'])

  const { mutate, isLoading } = useCancelOrganisationSubscription({
    onSuccess: () => {
      onClose?.()
    },
    onError: handleQueryError
  })

  const onSubmit = useCallback(() => {
    mutate()
  }, [mutate])

  return {
    isLoading,
    onSubmit,
    t
  }
}
export default useCancelSubscriptionModal
