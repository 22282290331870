import { z } from 'zod'

export const organisationEmployeeStd = z.object({
  organisationId: z.string().uuid(),
  userId: z.string().uuid(),
  username: z.string().trim(),
  email: z.string().email(),
  role: z.enum(['Admin', 'Candidate', 'Organisation', 'OrganisationEmployee'])
})

export type IOrganisationEmployee = z.infer<typeof organisationEmployeeStd>
