import { useTranslation } from 'next-i18next'

import InformationModal from 'components/modals/InformationModal'

import useModalStore from 'store/useModalStore'

const AfterPayingProFormaModal = () => {
  const { t } = useTranslation(['crm'])
  const { showAfterPayingProFormaModal, setShowAfterPayingProFormaModal } =
    useModalStore()

  return (
    <InformationModal
      isOpen={showAfterPayingProFormaModal}
      onClose={() => setShowAfterPayingProFormaModal(false)}
      title={t('org.popUp.afterPaymentProForma.title', {
        ns: 'crm'
      })}
      titleVariant={'h3'}
    >
      <div className="text-md">
        {t('org.popUp.afterPaymentProForma.description', {
          ns: 'crm'
        })}
      </div>
    </InformationModal>
  )
}

export default AfterPayingProFormaModal
