import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import { IFormChangeEmailProps } from './types'

const useFormChangeEmail = ({
  initialEmail
}: Pick<IFormChangeEmailProps, 'initialEmail'>) => {
  const initialValues = useMemo(
    () => ({
      email: initialEmail ?? ''
    }),
    [initialEmail]
  )
  const { t } = useTranslation(['inputs', 'userProfile', 'actions'])

  return { t, initialValues }
}

export default useFormChangeEmail
