import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { AdvertVisibilityType } from 'enums'

import useAdNewStore from 'store/useAdNewStore'
import { postAd, updateAd } from 'hooks/useAdvert/advert.service'

import { draftValidator } from 'utils/draftValidator'

import useBeforeUnload from './useBeforeUnload'

const usePageLeave = () => {
  const [isPageLeave, setPageLeave] = useState(false)
  const [route, setRoute] = useState<string | null>(null)
  const { t } = useTranslation(['ad'])
  const router = useRouter()
  const isEditing = useAdNewStore((state) => state.isEditing)
  const tempAd = useAdNewStore((state) => state.tempAd)
  const step = useAdNewStore((state) => state.activeStep)
  const clearAd = useAdNewStore((state) => state.clearAd)

  const { mutate, isLoading, isSuccess } = useMutation(
    isEditing ? updateAd : postAd,
    {
      onSuccess: (advert) => {
        const key = `toast.${AdvertVisibilityType[advert!.visibilityType]}.${
          isEditing ? 'update' : 'create'
        }`
        toast.success(t(key, { ns: 'ad' }))

        clearAd()
      },
      onError: () => {
        if (!tempAd?.visibilityType) {
          return
        }

        const key = `toast.${
          AdvertVisibilityType[tempAd.visibilityType]
        }.error${isEditing && 'Update'}`
        toast.error(t(key, { ns: 'ad' }))
      }
    }
  )

  const handleConfirmLeavePage = useCallback(
    (isCreateDraft: boolean) => {
      if (isCreateDraft && tempAd) {
        const ad = draftValidator(step).parse(tempAd)
        mutate({
          ad: {
            ...ad,
            id: tempAd.id,
            visibilityType: AdvertVisibilityType.Draft,
            creationStep: step,
            translation: tempAd?.translation
          }
        })
      }

      if (!route) {
        return
      }

      router.push(route)
    },
    [tempAd, route, router, step, mutate]
  )

  const handleSetLeave = useCallback(
    (openModalValue: boolean) => () => setPageLeave(openModalValue),
    []
  )

  useBeforeUnload(t('toast.jobAdPageLeaveModal', { ns: 'ad' }))

  useEffect(() => {
    const handler = (res: string) => {
      if (step < 1 || res.endsWith('/ad/new') || res.includes('/ad/edit')) {
        return
      }
      const isGoingToLeave = !isPageLeave
      if (
        isGoingToLeave &&
        (router.pathname === '/ad/new' ||
          router.pathname === '/ad/edit/[id]') &&
        (tempAd?.visibilityType === AdvertVisibilityType.Draft ||
          tempAd?.visibilityType === undefined)
      ) {
        setRoute(res)
        setPageLeave(true)
        // stopped page unmounting
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw 'stopped page unmounting'
      }
    }

    router.events.on('beforeHistoryChange', handler)

    return () => {
      router.events.off('beforeHistoryChange', handler)
    }
  }, [router, isPageLeave, step, tempAd?.visibilityType])

  return {
    isPageLeave,
    handleSetLeave,
    handleConfirmLeavePage,
    isLoading,
    isSuccess,
    t
  }
}

export default usePageLeave
