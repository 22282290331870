import { IArticleComment } from 'data-transfers/dto'
import {
  ICreateArticleCommentRequest,
  IGetArticleCommentsRequest
} from 'data-transfers/requests'

import { authClient, publicClient } from 'utils'

export const getArticleComments = async ({
  articleId
}: IGetArticleCommentsRequest) => {
  const { data } = await publicClient.get<IArticleComment[]>(
    `/community/${articleId}/comments`
  )

  return data
}

export const createArticleComments = async ({
  articleId,
  ...payload
}: ICreateArticleCommentRequest) => {
  await authClient.post<void>(`/community/${articleId}/comments`, payload)
}
