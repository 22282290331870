import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'

import useAdNewStore from 'store/useAdNewStore'
import useCVNewStore from 'store/useCVNewStore'
import useOrgNewStore from 'store/useOrgNewStore'
import useAuthManager from 'hooks/useAuthManager'
import { useRemoveUserAccount } from 'hooks/useUserAccount'

import { IModal } from '../types'

const useRemoveAccountModal = ({ onClose }: Pick<IModal, 'onClose'>) => {
  const { t } = useTranslation(['actions', 'userProfile'])
  const { signOut } = useAuthManager()
  const clearCV = useCVNewStore((state) => state.clearCV)
  const clearAd = useAdNewStore((state) => state.clearAd)
  const clearOrg = useOrgNewStore((state) => state.clearOrg)
  const router = useRouter()
  const { mutate, isLoading } = useRemoveUserAccount({
    onSuccess: () => {
      onClose?.()
      signOut()
      clearCV()
      clearAd()
      clearOrg()
      router.push('/')
    },
    onError: () => {
      toast.error(t('toast.cannotRemoveAccount', { ns: 'userProfile' }))
      onClose?.()
    }
  })

  return { mutate, isLoading, t }
}

export default useRemoveAccountModal
