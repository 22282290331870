import clsx from 'clsx'

import { Button, ButtonWithIcon } from 'components/buttons'
import { IconArrow } from 'icons'

import Modal from '../Modal'

import { IConfirmationModalProps } from './types'
import useConfirmationModal from './useConfirmationModal'

const ConfirmationModal = ({
  children,
  isOpen,
  tSubmit = 'yes',
  tCancel = 'cancel',
  isLoading,
  buttonsClassName,
  disabled,
  className,
  onClose,
  onSubmit,
  onCancel
}: IConfirmationModalProps) => {
  const { submitLabel, cancelLabel, onDenial } = useConfirmationModal({
    tSubmit,
    tCancel,
    onCancel,
    onClose
  })
  return (
    <Modal onClose={onClose} isOpen={isOpen} className={className}>
      <div className="h-fit overflow-y-auto py-10 px-10 text-lg text-gray-500 md:h-1/2 md:px-16">
        <div className="flex flex-col items-center text-center">{children}</div>
        <div
          className={clsx(
            'mt-2 flex justify-center gap-x-2 text-sm sm:mt-5 sm:gap-x-10 md:text-lg',
            buttonsClassName
          )}
        >
          <ButtonWithIcon
            isLoading={isLoading}
            disabled={disabled}
            isSpinner
            variant="accent"
            onClick={onSubmit}
            icon={IconArrow}
          >
            {submitLabel}
          </ButtonWithIcon>
          <Button disabled={disabled} onClick={onDenial}>
            {cancelLabel}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
