import React from 'react'
import { Props } from 'react-select'
import Select from 'react-select/async'
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc'

import { OptionLanguage } from '../../types'

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<OptionLanguage, true> & SortableContainerProps
>

export default SortableSelect
