import { useTranslation } from 'next-i18next'

import InformationModal from './InformationModal'
import { IModal } from './types'

const SubscriptionRestrictionModal = ({ isOpen, onClose }: IModal) => {
  const { t } = useTranslation(['auth'])

  return (
    <InformationModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('signin.title', { ns: 'auth' })}
    >
      {t('errors.subscriptionRestriction', { ns: 'auth' })}
    </InformationModal>
  )
}

export default SubscriptionRestrictionModal
