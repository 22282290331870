import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { AdvertTranslation } from 'enums'

import useAdNewStore from 'store/useAdNewStore'
import useOrgNewStore from 'store/useOrgNewStore'

import adUtils from 'utils/adUtil'

import { IModal } from '../types'

const useSelectLanguageModal = ({ onClose }: IModal) => {
  const { t } = useTranslation(['ad_form'])
  const countryCode = useOrgNewStore((state) => state.countryCode)
  const tempAd = useAdNewStore((state) => state.tempAd)
  const setTempAd = useAdNewStore((state) => state.setTempAd)
  const setPassedSteps = useAdNewStore((state) => state.setPassedSteps)
  const org = useAdNewStore((state) => state.org)

  const handleSelectLang = useCallback(
    (langs: AdvertTranslation | null) => () => {
      if (langs !== null) {
        const langCodes = adUtils.getAdvertTranslationsCodes(langs)

        setTempAd({
          ...tempAd,
          titles:
            langs == AdvertTranslation.EnglishLithuanian
              ? tempAd?.titles
              : tempAd?.titles?.map((x) => ({
                  ...x,
                  languageCode: langCodes[0]!
                })),
          descriptions: tempAd?.descriptions?.filter((x) =>
            langCodes.includes(x.languageCode)
          ),
          responsibilities:
            langs == AdvertTranslation.EnglishLithuanian
              ? tempAd?.responsibilities
              : tempAd?.responsibilities?.map((x) => ({
                  ...x,
                  languageCode: langCodes[0]!
                })),
          translation: langs
        })
        setPassedSteps([0])
      }
      onClose?.()
    },
    [onClose, setTempAd, tempAd, setPassedSteps]
  )

  return { t, org, countryCode, handleSelectLang }
}

export default useSelectLanguageModal
