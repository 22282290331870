import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { times } from 'constants/subscription'

import { ISubscriptionTimesSelectorProps } from './types'

const SubscriptionTimesSelector = ({
  selectedTime,
  className,
  onSelect,
  reverse,
  hideTrial
}: ISubscriptionTimesSelectorProps) => {
  const { t } = useTranslation(['pricing_plans'])
  return (
    <div
      className={clsx(
        'flex w-full items-center justify-center gap-5 text-xl font-semibold uppercase',
        reverse && 'flex-row-reverse',
        className
      )}
    >
      <div className={'flex gap-5'}>
        {times
          .filter((item) => item !== 'fortnight')
          .map((time) => (
            <div
              key={time}
              className={clsx(
                'cursor-pointer transition-colors',
                selectedTime === time && 'text-red-550'
              )}
              onClick={() => onSelect?.(time)}
            >
              {t(time, { ns: 'pricing_plans' })}
            </div>
          ))}
      </div>

      {!hideTrial && (
        <div
          className={clsx(
            'cursor-pointer transition-colors',
            selectedTime === 'fortnight' && 'text-red-550'
          )}
          onClick={() => onSelect?.('fortnight')}
        >
          {t('trySelector', { ns: 'pricing_plans' })}
        </div>
      )}
    </div>
  )
}

export default SubscriptionTimesSelector
