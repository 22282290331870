import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { OrganisationOnboardingStatus } from 'enums'

import InformationModal from 'components/modals/InformationModal'

import { ICompanyUnderReviewModalProps } from './types'

const CompanyUnderReviewModal = ({
  isOpen,
  onboardingStatus,
  onClose
}: ICompanyUnderReviewModalProps) => {
  const { t } = useTranslation(['crm'])
  const title = useMemo(() => {
    if (onboardingStatus === OrganisationOnboardingStatus.Rejected) {
      return t('org.popUp.rejected.title', { ns: 'crm' })
    }
    return t('org.popUp.underReview.title', { ns: 'crm' })
  }, [onboardingStatus, t])
  const description = useMemo(() => {
    if (onboardingStatus === OrganisationOnboardingStatus.Rejected) {
      return t('org.popUp.rejected.description', { ns: 'crm' })
    }
    return t('org.popUp.underReview.description', { ns: 'crm' })
  }, [onboardingStatus, t])

  return (
    <InformationModal
      isOpen={isOpen}
      titleVariant="h2"
      title={title}
      onClose={onClose}
    >
      <p className="text-sm text-gray-400">{description}</p>
    </InformationModal>
  )
}

export default CompanyUnderReviewModal
