import clsx from 'clsx'
import { ErrorMessage } from 'formik'

import { InputStyle } from 'components/Fields/Input'

import listSortCallback from 'utils/listSortCallback'
import { getZodMessage } from 'utils/validation.util'

import { SelectLanguagesProps } from '../types'

import {
  Option,
  SortableMultiValue,
  SortableMultiValueLabel,
  SortableSelect
} from './components'
import useSelectLanguagesDesktop from './useSelectLanguagesDesktop'

const SelectLanguagesDesktop = ({
  name,
  className,
  placeholder,
  helperText
}: SelectLanguagesProps) => {
  const {
    onChange,
    onSortEnd,
    options,
    selected,
    SelectStyle,
    document,
    t,
    setTouched
  } = useSelectLanguagesDesktop(name)

  return (
    <div className={clsx(InputStyle.fieldWrap, className)}>
      <SortableSelect
        name={name}
        useDragHandle
        axis="xy"
        onSortEnd={onSortEnd}
        styles={SelectStyle}
        distance={4}
        defaultOptions={options}
        value={selected}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        loadOptions={listSortCallback(options)}
        onChange={onChange}
        classNamePrefix="react-select"
        isMulti
        options={options}
        components={{
          Option,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          MultiValue: SortableMultiValue,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          MultiValueLabel: SortableMultiValueLabel
        }}
        menuPortalTarget={document?.body}
        menuPosition="fixed"
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        onBlur={() => setTouched(true)}
        placeholder={placeholder}
      />
      <div className={InputStyle.info}>
        <div className={InputStyle.helper}>{helperText}</div>
        <ErrorMessage
          name={name}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="fade-in text-medium text-error-400">
                {getZodMessage(msg, t(msg, { ns: 'zod' }))}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default SelectLanguagesDesktop
