import { FormCreateArticleComment } from 'components/Forms'

import ModalContent from '../ModalContent'

import { ICreateArticleCommentModalProps } from './types'
import useCreateArticleCommentModal from './useCreateArticleCommentModal'

const CreateArticleCommentModal = (props: ICreateArticleCommentModalProps) => {
  const { t, onCreateComment, isLoading } = useCreateArticleCommentModal(props)

  return (
    <ModalContent
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      className="text-center"
      title={
        props.parentCommentId
          ? t('replyComment', { ns: 'community' })
          : t('leaveComment', { ns: 'community' })
      }
    >
      <FormCreateArticleComment
        isLoading={isLoading}
        className="text-left lg:min-w-[60vw]"
        articleId={'article1'}
        onSubmit={onCreateComment}
      ></FormCreateArticleComment>
    </ModalContent>
  )
}

export default CreateArticleCommentModal
