import { ISubscriptionPriceRequest } from 'data-transfers/requests'

export const subscriptionsKeys = {
  all: ['subscriptions'] as const,
  organisationSubscriptions: () =>
    [...subscriptionsKeys.all, 'organisation_subscription'] as const,
  availableSubscriptions: () =>
    [...subscriptionsKeys.all, 'available_subscriptions'] as const,
  subscriptionPrice: (payload: ISubscriptionPriceRequest) =>
    [...subscriptionsKeys.all, 'subscription_price', payload] as const
}
