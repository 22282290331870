import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'

import ConfirmationModal from 'components/modals/ConfirmationModal'
import { IDefaultModal } from 'components/modals/types'
import Spinner from 'components/Spinner'
import Title from 'components/Title'

import { useSetCVAutoApplyStep } from 'hooks/useCVCreator'

const AutoApplyModal = ({
  isOpen,
  isLoading: externalLoading,
  onClose
}: IDefaultModal) => {
  const { t } = useTranslation(['crm'])

  const { mutate: onSubmit, isLoading } = useSetCVAutoApplyStep({
    onSuccess: () => {
      onClose?.()
      toast.success(t('user.toast.autoApplyChanged', { ns: 'crm' }))
    }
  })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      disabled={isLoading || externalLoading}
      className="relative"
      tSubmit={t('enable', { ns: 'actions' })}
      tCancel={t('no', { ns: 'actions' })}
      onSubmit={() => onSubmit({ autoApply: true })}
      onCancel={() => onSubmit({ autoApply: false })}
    >
      <Spinner
        className="pointer-events-none absolute top-3 left-3"
        isVisible={isLoading || externalLoading}
        size={42}
      />
      <Title variant={'h1'} className={'pb-5 text-black'}>
        {t('candidate.modals.autoApplyModal.title', { ns: 'crm' })}
      </Title>
      <div className={'max-w-3xl text-center'}>
        {t('candidate.modals.autoApplyModal.content', { ns: 'crm' })}
      </div>
      <div className={'mt-2 text-center'}>
        {t('candidate.modals.autoApplyModal.question', { ns: 'crm' })}
      </div>
    </ConfirmationModal>
  )
}

export default AutoApplyModal
