import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { IFormCreateArticleCommentProps } from 'components/Forms/FormCreateArticleComment/types'

import { useCreateArticleComment } from 'hooks/useArticleComments'

import { ICreateArticleCommentModalProps } from './types'

const useCreateArticleCommentModal = ({
  articleId,
  parentCommentId,
  onClose
}: ICreateArticleCommentModalProps) => {
  const { t } = useTranslation(['community'])
  const { mutateAsync, isLoading } = useCreateArticleComment({})

  const onCreateComment = useCallback<
    IFormCreateArticleCommentProps['onSubmit']
  >(
    async (payload, helpers) => {
      if (!articleId) {
        return
      }
      await mutateAsync({ ...payload, parentCommentId, articleId })
      toast.success(t('commentPosted', { ns: 'community' }))
      onClose?.()
      helpers.resetForm()
    },
    [articleId, mutateAsync, onClose, parentCommentId, t]
  )

  return {
    t,
    onCreateComment,
    isLoading
  }
}

export default useCreateArticleCommentModal
