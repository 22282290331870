import { Trans, useTranslation } from 'next-i18next'

import clsx from 'clsx'
import InformationModal from 'components/modals/InformationModal'
import { IDefaultModal } from 'components/modals/types'
import { format } from 'date-fns'
import Link from 'next/link'
import useRestrictionsStore from 'store/useRestrictionsStore'

const LinkText = ({
  href,
  className,
  children,
  onClick
}: {
  href: string
  className?: string
  children?: string
  onClick?: () => void
}) => {
  return (
    <Link
      href={href}
      onClick={() => onClick?.()}
      rel="noreferrer"
      className={clsx(
        'underline hover:text-red-550 group-hover:text-red-550 group-active:text-red-600',
        className
      )}
    >
      {children}
    </Link>
  )
}

const StartSubscriptionExpiringModal = ({
  isOpen,
  isLoading,
  onClose
}: IDefaultModal) => {
  const { t } = useTranslation(['crm'])
  const date = useRestrictionsStore(
    (state) => state.organisation?.currentSubscriptionEndDate
  )
  return (
    <InformationModal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={t('org.subscriptionNotification.expirationTrialPlan.title', {
        ns: 'crm'
      })}
      className="md:min-w-[650px]"
      titleVariant={'h2'}
    >
      <div className={'mt-2 text-lg'}>
        <Trans
          i18nKey={'org.subscriptionNotification.expirationTrialPlan.content'}
          t={t}
          ns={'crm'}
          values={{ date: date ? format(new Date(date), 'yyyy-MM-dd') : '' }}
          components={{
            text: (
              <LinkText
                className={'underline'}
                href={'/subscription'}
                onClick={onClose}
              />
            )
          }}
        />
      </div>
    </InformationModal>
  )
}

export default StartSubscriptionExpiringModal
