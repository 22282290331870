import { useCallback, useEffect, useMemo, useState } from 'react'

import { getFromClient, removeFromClient, setFromClient } from 'utils/cookies'

const TRY_AGAIN_TIME_MINUTES = 3
const getMinutes = (time?: number) => Math.floor(((time ?? 0) / 1000 / 60) % 60)
const getSeconds = (time?: number) => Math.floor(((time ?? 0) / 1000) % 60)

interface ITimerProps {
  cookieName: string
  delayMinutes?: number
}

const useTimer = ({
  cookieName,
  delayMinutes = TRY_AGAIN_TIME_MINUTES
}: ITimerProps) => {
  const [deadline, setDeadline] = useState<string | null>(null)
  const parsedDeadline = useMemo(
    () => (deadline ? Date.parse(deadline) : 0),
    [deadline]
  )
  const [time, setTime] = useState(() =>
    parsedDeadline ? parsedDeadline - Date.now() : 0
  )

  const minutes = useMemo(() => getMinutes(time), [time])
  const seconds = useMemo(() => getSeconds(time), [time])
  const enable = useMemo(() => minutes <= 0 && seconds <= 0, [minutes, seconds])

  useEffect(() => {
    if (minutes <= 0 && seconds <= 0) {
      setDeadline(null)
    }
  }, [minutes, seconds])

  useEffect(() => {
    setDeadline(getFromClient(cookieName))
  }, [cookieName])

  useEffect(() => {
    let timer: NodeJS.Timer | undefined = undefined
    if (!parsedDeadline) {
      clearInterval(timer)
      return
    }

    const updateTime = () => setTime(parsedDeadline - Date.now())
    updateTime()
    timer = setInterval(updateTime, 1000)

    return () => clearInterval(timer)
  }, [parsedDeadline])

  const onStart = useCallback(() => {
    const now = new Date()
    now.setTime(now.getTime() + delayMinutes * 60 * 1000)
    const dateString = now.toISOString()
    setDeadline(dateString)

    setFromClient(cookieName, dateString, {
      maxAge: delayMinutes * 60
    })
  }, [cookieName, delayMinutes])

  const onStartWithCustomDelay = useCallback(
    (dateTo: Date) => {
      const dateString = dateTo.toISOString()
      setDeadline(dateString)

      setFromClient(cookieName, dateString, {
        maxAge: dateTo.getTime()
      })
    },
    [cookieName]
  )
  const clearTimer = useCallback(() => {
    setDeadline(new Date().toISOString())
    removeFromClient(cookieName)
  }, [cookieName])
  return {
    onStart,
    enable,
    minutes,
    seconds,
    onStartWithCustomDelay,
    clearTimer
  }
}

export default useTimer
