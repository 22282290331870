import { useTranslation } from 'next-i18next'

import InformationModal from 'components/modals/InformationModal'
import { IModal } from 'components/modals/types'

const SubscriptionNotificationModal = ({
  isOpen,
  isLoading,
  onClose
}: IModal & { isLoading?: boolean }) => {
  const { t } = useTranslation(['crm'])

  return (
    <InformationModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={t('org.subscriptionNotification.tooManyActiveJobAds.title', {
        ns: 'crm'
      })}
      titleVariant={'h2'}
    >
      <div>
        {t('org.subscriptionNotification.tooManyActiveJobAds.content', {
          ns: 'crm'
        })}
      </div>
    </InformationModal>
  )
}
export default SubscriptionNotificationModal
