import axios from 'axios'

// import { fileClient } from 'utils'

export const postImage = async ({
  file,
  jwt
}: {
  file: File | Blob
  jwt: string
}) => {
  const response = await axios.post(
    `/api/v1/images`,
    { file: file },
    {
      headers: {
        Accept: 'application/text',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwt}`
      }
    }
  )
  // const response = await fileClient(jwt).post(`/images`, { file: file })
  if (response.status >= 200 && response.status < 300) {
    return response.data as string
  }
  return null
}
