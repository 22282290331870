import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import { IOrganisation } from 'data-transfers/dto'

import { FormSingleAddresses } from 'components/Forms'
import { FormInformation } from 'components/StepsOrg/Step0'
import { FormLanguages } from 'components/StepsOrg/Step2'
import { FormDescription } from 'components/StepsOrg/Step3'
import { FormSocialNetworks } from 'components/StepsOrg/Step4'
import { FormCompanyLogo } from 'components/StepsOrg/Step5'
import { FormCompanyPicture } from 'components/StepsOrg/Step6'
import { FormCompanyGallery } from 'components/StepsOrg/Step7'

import ModalContent from '../ModalContent'
import ModalFooter from '../ModalFooter'

import { IModalEditOrgProps, ModalFormMapType } from './types'

const ModalFormMap: ModalFormMapType = {
  information: {
    tTitle: 'overallInformation.title',
    tSubtitle: 'overallInformation.description',
    form: FormInformation
  },
  address: {
    tTitle: 'address.title',
    tSubtitle: 'address.description',
    form: FormSingleAddresses
  },
  languages: {
    tTitle: 'officialLanguages.title',
    tSubtitle: 'officialLanguages.description',
    form: FormLanguages
  },
  description: {
    tTitle: 'description.title',
    tSubtitle: 'description.description',
    form: FormDescription
  },
  socialNetwork: {
    tTitle: 'socialNetworks.title',
    tSubtitle: 'socialNetworks.description',
    form: FormSocialNetworks
  },
  companyLogo: { tTitle: 'companyLogo.title', form: FormCompanyLogo },
  companyPicture: {
    tTitle: 'companyPhoto.title',
    tSubtitle: 'companyPhoto.description',
    form: FormCompanyPicture
  },
  companyGallery: {
    tTitle: 'companyGallery.title',
    tSubtitle: 'companyGallery.description',
    form: FormCompanyGallery
  }
}

const EditOrgModal = ({
  openModal,
  onClose,
  onSubmit,
  org,
  arrayIndex,
  ...rest
}: IModalEditOrgProps) => {
  const { t } = useTranslation(['org_form'])
  const [isLoading, setIsLoading] = useState(false)
  const onFormSubmit = useCallback(
    async (values: Partial<IOrganisation>) => {
      setIsLoading(true)
      await onSubmit?.(values)
      setIsLoading(false)
      onClose?.()
    },
    [onClose, onSubmit]
  )

  const OpenModalForm = useMemo(() => {
    if (!openModal) {
      return null
    }
    return ModalFormMap[openModal]
  }, [openModal])
  if (!OpenModalForm) {
    return null
  }
  const { tTitle, tSubtitle, form: Form } = OpenModalForm

  return (
    <ModalContent
      {...rest}
      onClose={onClose}
      isOpen={!!openModal}
      title={t(tTitle, { ns: 'org_form' })}
      subtitle={tSubtitle && t(tSubtitle, { ns: 'org_form' })}
    >
      <Form
        onSubmit={onFormSubmit}
        initialValues={org}
        arrayIndex={arrayIndex}
        footer={<ModalFooter onClose={onClose} isLoading={isLoading} />}
      />
    </ModalContent>
  )
}

export default EditOrgModal
