import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button, ButtonWithIcon } from 'components/buttons'
import Input from 'components/Fields/Input'
import { IconArrow } from 'icons'

import { changeEmailValidator } from 'validators/changeEmailValidator'

import { IFormChangeEmailProps } from './types'
import useFormChangeEmail from './useFormChangeEmail'

const FormChangeEmail = ({
  isLoading,
  onSubmit,
  onCancel,
  initialEmail
}: IFormChangeEmailProps) => {
  const { t, initialValues } = useFormChangeEmail({ initialEmail })
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={toFormikValidationSchema(changeEmailValidator)}
      onSubmit={onSubmit}
    >
      <Form>
        <Input
          name="email"
          label={t('email.label2', { ns: 'inputs' })}
          placeholder={t('email.placeholder', { ns: 'inputs' })}
          type="email"
          required
        />
        <div className={clsx('mt-5 flex justify-center gap-x-10')}>
          <ButtonWithIcon
            isLoading={isLoading}
            isSpinner
            variant="accent"
            icon={IconArrow}
            type="submit"
          >
            {t('changeEmail', { ns: 'actions' })}
          </ButtonWithIcon>
          <Button onClick={onCancel}> {t('cancel', { ns: 'actions' })}</Button>
        </div>
      </Form>
    </Formik>
  )
}

export default FormChangeEmail
