import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import ConfirmationModal from 'components/modals/ConfirmationModal'
import { IDefaultModal } from 'components/modals/types'
import Spinner from 'components/Spinner'
import Title from 'components/Title'

const SubscriptionExpiringModal = ({
  isOpen,
  isLoading,
  onClose
}: IDefaultModal) => {
  const { t } = useTranslation(['crm', 'actions'])
  const router = useRouter()

  return (
    <ConfirmationModal
      isOpen={isOpen}
      disabled={isLoading}
      className="relative"
      tSubmit={t('payNow', { ns: 'actions' })}
      tCancel={t('remindMeLater', { ns: 'actions' })}
      onSubmit={() => {
        router.push('/subscription')
        onClose?.()
      }}
      onCancel={onClose}
    >
      <Spinner
        className="pointer-events-none absolute top-3 left-3"
        isVisible={isLoading}
        size={42}
      />
      <Title variant={'h2'} className={'pb-5 text-black'}>
        {t('org.subscriptionNotification.expirationSubscription.title', {
          ns: 'crm'
        })}
      </Title>
      <div className={'max-w-2xl text-center'}>
        {t('org.subscriptionNotification.expirationSubscription.content', {
          ns: 'crm'
        })}
      </div>
    </ConfirmationModal>
  )
}
export default SubscriptionExpiringModal
