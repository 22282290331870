import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import {
  IOrganisationEmployee,
  organisationEmployeeStd
} from 'validators/userManagementValidator'
import { authClient } from 'utils'

interface IUseUserManagement {
  orgId: string
}
interface IRemoveOrganisationEmployee {
  organisationEmployeeId: string
}

export const userManagementKeys = {
  all: ['userManagement'] as const,
  users: () => [...userManagementKeys.all, 'users'] as const,
  orgUsers: (orgId: string) =>
    [...userManagementKeys.users(), { orgId }] as const
}

const getUsers = async ({
  orgId
}: IUseUserManagement): Promise<{
  data: IOrganisationEmployee[]
  total: string
}> => {
  const { data, headers } = await authClient.get(
    `/organisations/users/${orgId}`
  )

  if (!data || !headers) return { data: [], total: '0' }
  const total = headers['content-range']?.split('/')[1]

  return { data, total: total ?? data.length ?? '0' }
}

const removeOrganisationEmployee = async ({
  organisationEmployeeId
}: IRemoveOrganisationEmployee) => {
  await authClient.delete(`user-profile/${organisationEmployeeId}`)
  return
}

const useUserManagement = ({ orgId }: IUseUserManagement) => {
  return useQuery<{
    data: IOrganisationEmployee[]
    total: string
  }>(userManagementKeys.orgUsers(orgId), async () => {
    const { data, total } = await getUsers({ orgId })

    const list: IOrganisationEmployee[] = []
    data.forEach((item) => {
      const parse = organisationEmployeeStd.safeParse(item)
      if (parse.success) {
        list.push(parse.data)
      } else {
        console.warn(parse)
      }
    })

    return { data: list, total }
  })
}
const useRemoveOrganisationEmployee = ({
  onSuccess,
  ...options
}: UseMutationOptions<void, unknown, IRemoveOrganisationEmployee>) => {
  const queryClient = useQueryClient()
  return useMutation(removeOrganisationEmployee, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(userManagementKeys.users())
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export { getUsers, useRemoveOrganisationEmployee, useUserManagement }
