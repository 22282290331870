import { useQuery } from '@tanstack/react-query'
import { IOkredoInfoResponse } from 'data-transfers/responses'

import { authClient } from 'utils'

import { IUseQueryHookWithPayload } from './types'

interface IOkredoOrgInfoRequest {
  companyCode: string
  countryCode: string
}

export const getOrgInfoByCompanyCode = async ({
  companyCode,
  countryCode
}: IOkredoOrgInfoRequest) => {
  const { data } = await authClient.get<IOkredoInfoResponse>(
    `/okredo/company/${companyCode}`,
    {
      params: {
        countryCode
      }
    }
  )
  return data
}

const useOrgInfo = ({
  payload,
  options
}: IUseQueryHookWithPayload<IOkredoOrgInfoRequest, IOkredoInfoResponse>) => {
  return useQuery(
    ['org_info', payload],
    async () => {
      const response = await getOrgInfoByCompanyCode(payload)
      return response
    },
    {
      retry: false,
      ...options
    }
  )
}

export default useOrgInfo
