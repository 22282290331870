import { useMutation } from '@tanstack/react-query'
import { IPayOrganisationSubscriptionRequest } from 'data-transfers/requests'
import { IInitiatePaymentResponse } from 'data-transfers/responses'

import { IUseMutation } from 'hooks/types'

import { payForSubscription } from './subscription.service'

const usePayForSubscription = (
  options: IUseMutation<
    IInitiatePaymentResponse,
    unknown,
    IPayOrganisationSubscriptionRequest
  > = {}
) => useMutation(payForSubscription, options)

export default usePayForSubscription
