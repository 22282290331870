import React, { MouseEventHandler } from 'react'
import { components, MultiValueProps } from 'react-select'
import { SortableElement } from 'react-sortable-hoc'
import clsx from 'clsx'

import { OptionLanguage } from '../../types'

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<OptionLanguage>) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    return (
      <components.MultiValue
        {...props}
        innerProps={innerProps}
        className="z-[51] bg-gray-900 text-white"
      >
        <span
          className={clsx(
            'fp fp-md fp-rounded scale-75 ring ring-white',
            props.data?.code
          )}
        ></span>
        <span className="ml-2 font-medium text-white">{props.data.label}</span>
      </components.MultiValue>
    )
  }
)

export default SortableMultiValue
