import ConfirmationModal from '../ConfirmationModal'

import { IRemoveOrganisationEmployeeModalProps } from './types'
import useRemoveOrganisationEmployeeModal from './useRemoveOrganisationEmployeeModal'

const RemoveOrganisationEmployeeModal = ({
  removeUser,
  onClose
}: IRemoveOrganisationEmployeeModalProps) => {
  const { t, isLoading, removeOrganisationEmployee } =
    useRemoveOrganisationEmployeeModal({
      removeUser,
      onClose
    })
  return (
    <ConfirmationModal
      isLoading={isLoading}
      isOpen={!!removeUser}
      onClose={onClose}
      onSubmit={removeOrganisationEmployee}
    >
      <div>{t('org.management.removeUserQuestion', { ns: 'crm' })}</div>
    </ConfirmationModal>
  )
}

export default RemoveOrganisationEmployeeModal
