import { useTranslation } from 'next-i18next'

import InformationModal from 'components/modals/InformationModal'

import useModalStore from 'store/useModalStore'

const ProhibitNavigationModal = () => {
  const showProhibitNavigationModal = useModalStore(
    (state) => state.setShowProhibitNavigationModal
  )
  const isOpen = useModalStore((state) => state.showProhibitNavigationModal)
  const { t } = useTranslation(['crm'])

  return (
    <InformationModal
      isOpen={isOpen}
      onClose={() => showProhibitNavigationModal(false)}
      title={t('candidate.modals.prohibitNavigationModal.title', {
        ns: 'crm'
      })}
      titleVariant={'h2'}
    >
      <div className=" ">
        {t('candidate.modals.prohibitNavigationModal.content', {
          ns: 'crm'
        })}
      </div>
    </InformationModal>
  )
}

export default ProhibitNavigationModal
