import { useTranslation } from 'next-i18next'
import React, { useRef } from 'react'
import Select from 'react-select'
import { closestCenter, DndContext } from '@dnd-kit/core'
import {
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import clsx from 'clsx'

import { useDocument } from 'hooks/useDocument'

import { getZodMessage } from 'utils/validation.util'

import { InputStyle } from '../../Input'
import { SelectLanguagesProps } from '../types'

import SelectLanguagesMobileItem, { Option, SortableValue } from './components'
import useSelectLanguagesMobile from './useSelectLanguagesMobile'

function SelectLanguagesMobile({
  name,
  className,
  placeholder,
  helperText
}: SelectLanguagesProps) {
  const { t } = useTranslation('zod')
  const ref = useRef<HTMLDivElement>(null)
  const document = useDocument()
  const {
    onChange,
    onDelete,
    handleDragEnd,
    items,
    sensors,
    options,
    countriesSelect
  } = useSelectLanguagesMobile(name)

  return (
    <div className={clsx('relative overflow-hidden', className)}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={items}
          strategy={
            window.innerWidth < 700
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          <div className={'mt-2'}>
            <div
              className={clsx(
                `absolute flex w-[95%] flex-col flex-wrap gap-x-1 gap-y-[5px] pt-1 sm:flex-row`
              )}
              ref={ref}
            >
              {items.length > 0 ? (
                items.map((id) => {
                  const lang = options.find((item) => item.label === id)

                  if (lang) {
                    return (
                      <SelectLanguagesMobileItem
                        key={id}
                        id={id}
                        lang={lang}
                        onDelete={onDelete}
                      />
                    )
                  }
                  return null
                })
              ) : (
                <div className={'z-[51] text-gray-500'}>{placeholder}</div>
              )}
            </div>
          </div>

          <Select
            components={{ Option, SingleValue: SortableValue }}
            options={countriesSelect}
            name={name}
            styles={{
              menuPortal: (provided) => ({
                ...provided,
                zIndex: '50'
              }),
              valueContainer: (provided) => ({
                ...provided,
                opacity: 0,
                height: ref?.current
                  ? `${ref.current?.offsetHeight + 5}px`
                  : 'fit-content'
              }),
              dropdownIndicator: (provided, state) => ({
                ...provided,
                transition: 'transform 300ms',
                transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)'
              }),
              control: (provided, state) => ({
                ...provided,
                minheight: '40px',
                color: '#E5E7EB',
                borderTop: 'none',
                borderleft: 'none',
                borderRight: 'none',
                outline: 'none',
                borderRadius: '0px',
                borderLeftWidth: '0px',
                borderBottomColor: state.isFocused ? '#9ca3af' : '#e5e7eb',
                boxShadow: 'none',
                '&:hover': {
                  color: '#9ca3af'
                }
              })
            }}
            placeholder={placeholder}
            onChange={onChange}
            menuPortalTarget={document?.body}
            menuPosition="fixed"
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            className={'z-[50]'}
            isSearchable={false}
          />

          <div className={clsx(InputStyle.helper, 'my-2')}>{helperText}</div>
          {items.length === 0 && (
            <div className="fade-in text-xs text-error-400">
              {getZodMessage(
                'custom.select1Language',
                t('custom.select1Language', { ns: 'zod' })
              )}
            </div>
          )}
        </SortableContext>
      </DndContext>
    </div>
  )
}

export default SelectLanguagesMobile
