import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { OrganisationOnboardingStatus } from 'enums'
import { ErrorMessage, Form, Formik } from 'formik'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import Dropzone from 'components/Dropzone'
import ImageEditor from 'components/ImageEditor'
import { IconArrow, IconCompany } from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import { postImage } from 'hooks/useImage'
import { useSaveOrganisationStep } from 'hooks/useOrganisation'

import dataURItoBlob from 'utils/dataURIToBlob'
import { getZodMessage } from 'utils/validation.util'

import { IOrgStepProps } from './types'

export const FormCompanyLogo = ({
  initialValues,
  footer,
  onSubmit
}: IOrgStepProps<{ logoUrl: string }>) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['actions', 'org_form', 'zod'])
  const formikRef = useRef<any>(null)
  const incStep = useOrgNewStore((state) => state.incStep)
  const decStep = useOrgNewStore((state) => state.decStep)
  const { data: session } = useSession()
  const jwt = session?.jwt
  const tempOrg = useOrgNewStore((state) => state.tempOrg)

  const currentOrg = useMemo(() => {
    if (initialValues) {
      return {
        logoUrl: initialValues?.logoUrl ?? ''
      }
    }
    return { logoUrl: tempOrg?.logoUrl ?? '' }
  }, [initialValues, tempOrg?.logoUrl])

  const ref = useRef<AvatarEditor>(null)
  const [image, setImage] = useState<File | string | null>(null)
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    noKeyboard: true,
    noClick: false,
    maxSize: 21000000,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        handleImage(acceptedFiles[0], formikRef?.current?.setErrors)
      }
    }
  })

  const handleImage = useCallback(
    (img: File | null, setErrors?: (error: any) => void) => {
      setImage(img)
      if (setErrors) {
        setErrors({})
      }
    },
    []
  )

  useEffect(() => {
    if (currentOrg?.logoUrl) {
      setImage(currentOrg?.logoUrl)
    }
  }, [currentOrg])

  const { mutate, isLoading: isLoadingOrganisation } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.Logo
  )

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ image: '' }}
      enableReinitialize
      onSubmit={async (_v, helper) => {
        if (ref.current) {
          setIsLoading(true)
          try {
            const file = dataURItoBlob(
              ref.current.getImageScaledToCanvas().toDataURL()
            )
            if (file && jwt) {
              const logoUrl = await postImage({
                jwt,
                file
              })
              if (!logoUrl) {
                return
              }
              if (onSubmit) {
                onSubmit({ logoUrl })
                return
              }

              mutate({ data: { logoUrl } })
            } else {
              helper.setErrors({
                image: t('companyLogo.error', { ns: 'org_form' })
              })
              return
            }
          } catch (e) {
            incStep(OrganisationOnboardingStatus.Photo)
            return
          }
        } else {
          helper.setErrors({
            image: t('companyLogo.error', { ns: 'org_form' })
          })
          return
        }

        incStep(OrganisationOnboardingStatus.Photo)
        setIsLoading(false)
      }}
    >
      {(props) => (
        <Form>
          <div className="flex flex-col justify-center">
            <div className="mb-10 h-auto w-fit">
              {image && (
                <ImageEditor
                  ref={ref}
                  borderRadius={0}
                  image={image}
                  defaultScale={'0'}
                  handleImage={(img) => handleImage(img, props.setErrors)}
                  crossOrigin={'anonymous'}
                />
              )}
              {!image && (
                <Dropzone
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  className="mx-auto aspect-square max-w-[300px]"
                >
                  <span className="block h-full w-full">
                    <IconCompany />
                  </span>
                  <span className="block pt-10">
                    {t('photoForm.fileButton', { ns: 'org_form' })}
                  </span>
                </Dropzone>
              )}{' '}
              {footer && (
                <ErrorMessage name={'image'}>
                  {(error) => (
                    <span className="fade-in font-medium tracking-wide text-error-400">
                      {getZodMessage(error, t('zod:' + error))}
                    </span>
                  )}
                </ErrorMessage>
              )}
            </div>
            {footer || (
              <>
                <ErrorMessage name={'image'}>
                  {(error) => (
                    <span className="fade-in font-medium tracking-wide text-error-400">
                      {getZodMessage(error, t('zod:' + error))}
                    </span>
                  )}
                </ErrorMessage>

                <div className="mt-4 flex items-center justify-between">
                  <Button
                    onClick={() =>
                      decStep(OrganisationOnboardingStatus.SocialNetworks)
                    }
                  >
                    <span>{t('back', { ns: 'actions' })}</span>
                  </Button>
                  <Button
                    className="animated-svg"
                    type="submit"
                    isLoading={
                      props.isSubmitting || isLoading || isLoadingOrganisation
                    }
                  >
                    <span className=" mr-2">
                      {t('next', { ns: 'actions' })}
                    </span>
                    <IconArrow />
                  </Button>
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

const OrgStep5 = ({ index, step }: { index: number; step?: string }) => {
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)

  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
    >
      <FormCompanyLogo />
    </AccordionItem>
  )
}

export default OrgStep5
