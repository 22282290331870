import ConfirmationModal from '../ConfirmationModal'
import { IModal } from '../types'

import useCancelSubscriptionModal from './useCancelSubscriptionModal'

const CancelSubscriptionModal = ({ isOpen, onClose }: IModal) => {
  const { isLoading, onSubmit, t } = useCancelSubscriptionModal({ onClose })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      onSubmit={onSubmit}
      tSubmit={{ key: 'org.cancelationModal.yes', ns: 'crm' }}
      tCancel={{ key: 'org.cancelationModal.no', ns: 'crm' }}
    >
      <div className="max-w-2xl  whitespace-pre-line">
        <div>{t('org.cancelationModal.description', { ns: 'crm' })}</div>
        <div className="mt-2">
          {t('org.cancelationModal.question', { ns: 'crm' })}
        </div>
      </div>
    </ConfirmationModal>
  )
}
export default CancelSubscriptionModal
