import { useQuery } from '@tanstack/react-query'
import { IOrganisationSubscriptionResponse } from 'data-transfers/responses'

import { IUseQueryHook } from 'hooks/types'

import { subscriptionsKeys } from './keys'
import { getOrganisationSubscriptions } from './subscription.service'

const useOrganisationSubscriptions = ({
  options
}: IUseQueryHook<never, IOrganisationSubscriptionResponse>) => {
  return useQuery<IOrganisationSubscriptionResponse>(
    subscriptionsKeys.organisationSubscriptions(),
    async () => {
      const data = await getOrganisationSubscriptions()
      return data
    },
    options
  )
}

export default useOrganisationSubscriptions
